import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
//Import cross icon
import CloseIcon from '@mui/icons-material/Close';
import './style.css'
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  height:'80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
};



export default function AccountBalanceModal({button,url}) {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const handleOpenClick = () => {
    setOpen(true)

  }



  return (
    <>
    <div>
    
     
      <IconButton
        aria-label='delete'
        size='small'
        onClick={() => {handleOpenClick()}}
     
      >
        {button}
      </IconButton>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        
      >
        <Box sx={style}>
        <CloseIcon
        fontSize='large'
        sx={{ position: 'absolute', top: 0, right: 0, color: 'grey', cursor: 'pointer' }}
            onClick={handleClose}
          /> 
          <img src={url}
           alt="estadoCC"
           height={'100%'}
           />

        
        </Box>
      </Modal>
    </div>
    </>
  );
}
