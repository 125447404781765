
// import React, {useContext} from "react"
// import { Button } from "@mui/material"
// import {getCustomerAll} from '../../../services/customerServices'
// import {userContext} from '../../../context/userContext'
const BlankPage = () => {
// const {userID,instance,accounts} = useContext(userContext);


    return (
        <div className="blank-page">
            <div className="blank-page__container">
                <div className="blank-page__container__content">
             {/* <Button onClick={()=>{
                   const customers =   getCustomerAll(userID,instance,accounts)

             }}>Hola</Button> */}
                </div>
            </div>
        </div>
    )
}


export default BlankPage