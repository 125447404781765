import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import { postResumenDeCuenta } from '../../../services/estadoCuentaServices';
import { Alert } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { postResumenDeCuentaHistorico } from '../../../services/estadoCuentaServices';
import { userContext } from '../../../context/userContext';
import { getCustomerProfile } from '../../../services/customerServices';
import HelpIcon from '@mui/icons-material/Help';
import './styleAccountBalance.css';
import AccountBalanceModal from './AccountBalanceModal';
const AccountBalance = () => {
  const [value, setValue] = React.useState(0);
  const { instance, accounts, company, userID } = React.useContext(userContext);
  const [messageDelay, setMessageDelay] = React.useState(false);
  const { data } = useQuery(
    {
      queryKey: ['AccountGUID', userID],
      queryFn: () => getCustomerProfile(userID, instance, accounts),
      select: (data) => data?.EmailAddress1.replace(';', ','),
    },
    {
      cacheTime: 60000, // cache for 1 minute
    }
  );

  const handleResumenCuentaCorriente = () => {

    postResumenDeCuenta(instance, accounts, userID, company).then((response) => {
      setMessageDelay(true);
      setTimeout(hideMessage, '8000');
    });
  };

  const hideMessage = () => {
    setMessageDelay(false);
  };

  const handleResumenDeCuentaHistorico = () => {
   
    postResumenDeCuentaHistorico(instance, accounts, userID, company).then((response) => {
      setMessageDelay(true);
      setTimeout(hideMessage, '8000');
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        style={{ display: 'flex', padding: 15, width: '40%',            maxWidth: 600,
      }} // Agrega el estilo flex aquí
        {...other}
      >
        {value === index && <> {children}</>}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }
  return (
    <Box
      sx={{
        flexDirection: 'row',
        bgcolor: 'white',
        display: 'flex',
        height: 'auto',
      }}
    >
      <Tabs
        orientation='vertical'
        variant='scrollable'
        value={value}
        onChange={handleChange}
        sx={{
          borderRight: 1,
          borderColor: 'divider',
          width: 300,
          '& .MuiTabs-indicator': {
            backgroundColor: '#3CB9BD',
            width: 7,
          },
        }}
      >
        <Tab
          sx={{
            height: 150,
            '&.Mui-selected': {
              color: '#6E726E',
            },
          }}
          label='Resumen de cuenta corriente'
          {...a11yProps(0)}
        />
        <Tab
          sx={{
            height: 150,

            '&.Mui-selected': {
              color: '#004D40',
            },
          }}
          label='Resumen histórico de cuenta corriente'
        />
        
      </Tabs>
      <TabPanel
        value={value}
        index={0}
        {...a11yProps(1)}
      >
        <Box
          sx={{
            backgroundColor:'#EDEDED',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 1,
            paddingBottom:1,
          //  border: '1px solid #D0D0D0',
            gap: 4,
            justifyContent: 'space-evenly',
          }}
        >
          <Box sx={{display:'flex',justifyContent:'flex-end'}}>
            <AccountBalanceModal button={
            <HelpIcon sx={{color:'#3CB9BD' ,fontSize:36,margin:0.5}}/> }url={"https://cortechap.blob.core.windows.net/customerportal/estado-cc.jpg?sp=r&st=2023-10-19T19:26:52Z&se=2029-10-20T03:26:52Z&spr=https&sv=2022-11-02&sr=b&sig=Ev7qV6QU0nEUDEzwTNSJ%2F78dzOeuCrDSIECV2HQwEAc%3D"}/>

               </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: 0.2,
              flex: 3,
              backgroundColor:'#EDEDED'
            }}
          >

            <Typography
              gutterBottom
              color='#1D1D1B'

              sx={{ fontSize: 20, fontWeight: 400, textAlign: 'center'}}
              component='div'
            >
              Resumen de cuenta corriente
            </Typography>
            <Typography
              sx={{ fontSize: 13, fontWeight: 400 }}
              color='#1D1D1B'
              width={'60%'}
              textAlign={'center'}
            >
              Haga clic a continuación para recibir su resumen de cuenta corriente en el correo electrónico registrado.
            </Typography>

            
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flex: 1, flexGrow: 1 }}>
            <Button
            disabled={messageDelay}
              sx={{
                padding: 1.2,
                width: '97%',
                border: 'none',
                borderRadius: 1,
                fontWeight: 500,
                fontSize: 14,
                backgroundColor: '#FF9636',
                color: 'white',
                '&:hover': { backgroundColor: '#FF9636', color: 'white' },
              }}
              onClick={() => {
                handleResumenCuentaCorriente();
              }}
            >
              Enviar por correo electrónico
            </Button>

          </Box>
          {messageDelay && (
            <Alert
              severity='success'
              sx={{ fontWeight: 300, fontSize: 12 }}
            >
              Se envió el reporte de cuenta corriente a la cuenta registrada por email {data}
            </Alert>
          )}

        </Box>
      </TabPanel>

      <TabPanel
        value={value}
        index={1}
      >
        <Box
          sx={{
            backgroundColor:'#EDEDED',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 1,
            paddingBottom:1,
           // border: '1px solid #D0D0D0',
            gap: 4,
            justifyContent: 'space-evenly',
          }}
        >
          <Box sx={{display:'flex',justifyContent:'flex-end'}}>
          <AccountBalanceModal button={<HelpIcon sx={{color:'#3CB9BD' ,fontSize:36,margin:0.5}}/>} url={"https://cortechap.blob.core.windows.net/customerportal/estado-historico.jpg?sp=r&st=2023-10-19T19:26:12Z&se=2029-10-20T03:26:12Z&spr=https&sv=2022-11-02&sr=b&sig=i4L0UIbpGLxWuAVzYFO%2B3DjhHdNZAEC5%2BrVOEsjL83A%3D"}/>

                                  </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: 0.2,
              flex: 3,
              backgroundColor:'#EDEDED'
            }}
          >
            <Typography
              gutterBottom
              sx={{ fontSize: 20, fontWeight: 400, textAlign: 'center'}}
              component='div'
            >         

              Resumen histórico de cuenta corriente
            </Typography>
            <Typography
              sx={{ fontSize: 13, fontWeight: 400 }}
              width={'60%'}
              textAlign={'center'}
            >
              Haga clic a continuación para recibir su resumen histórico de cuenta corriente en el correo electrónico
              registrado.{' '}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flex: 1, flexGrow: 1 }}>
            <Button
                        disabled={messageDelay}

              sx={{
                
                padding: 1.2,
                width: '97%',
                border: 'none',
                borderRadius: 1,
                fontWeight: 500,
                fontSize: 14,
                backgroundColor: '#FF9636',
                color: 'white',
                '&:hover': { backgroundColor: '#FF9636', color: 'white' },
              }}
              onClick={() => {
                handleResumenDeCuentaHistorico();
              }}
              fullWidth
              size='small'
            >
              Enviar por correo electrónico
            </Button>
          </Box>
        {messageDelay && (
          <Alert severity='success'>
            Se envió el reporte de cuenta corriente histórico a la cuenta registrada por email {data}{' '}
          </Alert>
        )}
                </Box>

      </TabPanel>
    </Box>
  );
};
export default AccountBalance;
