import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { format, add } from 'date-fns';
import roundTotalQuantity from '../../../helper/reduceDigits';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
export default function DollarTable({ data }) {
  const [rows, setRows] = useState([]);

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: 'white',
    },
    '&:nth-of-type(even)': {
      backgroundColor: '#f2f2f2',
    },
  }));

  function calculateExchangeRateDifferences(data) {
    const length = data.length;
    const exchangeRateDifferences = [];
    let fullArray = [];
    for (let i = length - 1; i > 0; i--) {
      const currentDayData = data[i];
      const previousDayData = data[i - 1];

      const currentDayRate = currentDayData.ExchangeRate;
      const previousDayRate = previousDayData.ExchangeRate;

      const difference =((previousDayRate / currentDayRate ) - 1) * 100;
      exchangeRateDifferences.push({
        difference: difference,
        ExchangeDate: previousDayData.ExchangeDate,
      });
    }
    fullArray = addPropertyToObjects(data, exchangeRateDifferences);

    return fullArray;
  }

  function addPropertyToObjects(data1, data2) {
    const mergedData = [];
    for (let i = 0; i < data1.length; i++) {
      const id = data1[i].ExchangeDate;
      const match = data2.find((item) => item.ExchangeDate === id);
      if (match && match.hasOwnProperty('difference')) {
        mergedData.push({ ...data1[i], difference: match.difference });
      } else {
        mergedData.push(data1[i]);
      }
    }
    return mergedData;
  }

  const obtenerRegistrosPasadosSinFinesDeSemana = (arrayDeObjetos) => {
    const fechaHoy = new Date(); // Obtener la fecha actual
    const arrayFiltrado = arrayDeObjetos.filter((objeto) => {
    const fechaExchange = new Date(objeto.ExchangeDate);

      return (
        fechaExchange.toDateString() !== fechaHoy.toDateString() &&
        fechaExchange < fechaHoy &&
        fechaExchange.getDay() !== 5 &&
        fechaExchange.getDay() !== 6
      );
    });

    return arrayFiltrado;
  };
  useEffect(() => {
      const sortedDataDivisa = data
      .filter((item) => item.CurrencyName.trim() === 'DOLAR DIVISA')
      .sort((a, b) => new Date(b.ExchangeDate) - new Date(a.ExchangeDate))
      .slice(0, 32);

      const otroarray = calculateExchangeRateDifferences(sortedDataDivisa).slice(0, 31);
      const newArray = obtenerRegistrosPasadosSinFinesDeSemana(otroarray);

    setRows(roundTotalQuantity(newArray));

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TableContainer
      style={{ maxHeight: 400 }}
      component={Paper}

    >
      <Table
        stickyHeader
        sx={{ minWidth: 300 }}
        aria-label='simple table'
      >
        <TableHead>
          <TableRow sx={{  padding: 0, margin: 0 }}>
            <TableCell
              sx={{           backgroundColor: '#3CB9BD'   , color: 'white', padding: 0, margin: 0 }}
              align='center'
            >
              Fecha
            </TableCell>
            <TableCell
              sx={{ backgroundColor: '#3CB9BD', color: 'white', padding: 0.5, margin: 0 }}
              align='center'
            >
              Cambio Divisa
            </TableCell>
            <TableCell
              sx={{ backgroundColor: '#3CB9BD', color: 'white', padding: 0.5, margin: 0 }}
              align='center'
            >
              Variación
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.ExchangeDate}>
              <TableCell align='center'>
                {format(add(new Date(row.ExchangeDate), { days: 1 }), 'dd/MM/yyyy')}{' '}
              </TableCell>
              <TableCell align='center'>$ {row.ExchangeRate}</TableCell>
              <TableCell align='center' sx={{display:'flex',alignItems:'center',alignContent:'center',justifyContent:'center'}}>
                {parseFloat(row.difference?.replace(',', '.')) === 0 || row.difference === undefined ? (
                  <Box sx={{paddingLeft:-5,marginLeft:-2.45}}>
                                    <ArrowForwardIcon style={{ color: '#FF9636' }} />
                                    </Box>
                  // <HorizontalRuleIcon style={{marginRight:3,marginLeft:-5, color: 'orange' }} />
                ) : parseFloat(row.difference?.replace(',', '.')) > 0 ? (
                  <ArrowUpwardIcon style={{ color: '#3CB9BD' }} />
                ) : (
                  <ArrowDownwardIcon style={{ color: 'red' }} />
                )}
                {row.difference} %
              </TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
