import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import './styleChildCard.css';
import {useTheme} from '@mui/material/styles';
export default function ChildCard({ children, title,color, subheader, padding = 2, paddingContent = '16px', paddingBottom = '24px' }) {
  const theme = useTheme();
  
  return (
    <Card
      sx={{
        width:'100%',
       // boxShadow: 3,

      //  borderRadius: 4,
        overflow: 'hidden',
      }}
      elevation={0}
    >
      <CardHeader
        title={title}
        titleTypographyProps={{ fontSize: 14, color: 'white' }}//, fontWeight: 'bold'
        subheader={subheader}
        subheaderTypographyProps={{ fontSize: 12, color: 'white' }} //, fontWeight: 'bold',
        sx={{
          backgroundColor: color,  //rgba(0, 77, 64, 0.8)
          pt: 1.5,
          pb: 1,
          '& .MuiCardHeader-content': {
            display:'flex',
            gap: 3,
            alignItems: 'center',
            justifyContent:'space-between'
          },
          '& .MuiCardHeader-title': {
            textTransform: 'uppercase',
            letterSpacing: '0.05em',
            textAlign: 'left', 
          },
          '& .MuiCardHeader-subheader': {
            opacity: 0.8,
            letterSpacing: '0.05em', 
            textAlign: 'left',
          },
          '& .MuiCardHeader-root': {
            padding: '24px 16px',
            borderBottom: 'none',
          },
        }}
      />
      <CardContent
        sx={{
          p: padding,
          backgroundColor: theme.palette.background.card,
          '&:first-child': {
            paddingLeft: paddingContent,
          },
          '&:last-child': {
            paddingRight: paddingContent,
            paddingBottom: paddingBottom
          },
        }}
      >
        {children}
      </CardContent>
    </Card>
  );
}
