import * as React from 'react';

const ErrorPage = () => {
 




  return (
    <>
      <div >
          <p>404</p>
          <p>Page not found</p>
   </div>
    </>
  );
};

export default ErrorPage;
