import React from 'react';
import { getLastDollar } from '../../services/dolarServices';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { format, parseISO } from 'date-fns';
import { useMsal } from '@azure/msal-react';
import { useQuery } from '@tanstack/react-query';
export function DollarBox({ open }) {
  const { instance, accounts } = useMsal();
  const { data, isError } = useQuery({
    queryKey: 'dollarLastGet',
    queryFn: () => getLastDollar(instance, accounts),
    select: (data) => {
      return {
        valorDolar: data.data.data[0].ExchangeRate.toLocaleString('es'),
        fechaDolar: format(parseISO(data.data.data[0].ExchangeDate), 'dd/MM/yyyy'),
      };
    },
  });

  if (isError) {
    return (
      <div>
        <h3>Algo ha fallado..</h3>
      </div>
    );
  }

  return (
    <>
      {data && open ? (
        <Box

          display='block'
          sx={{ display: 'block', padding:2, textAlign: 'center',backgroundColor:'#EDEDED' }}
        >
          <Typography sx={{ fontSize: 13 }}>Dolar divisa vendedor</Typography>
          <Typography sx={{ fontSize: 13,display:'flex' }}>{data?.fechaDolar}</Typography>

          <Box sx={{ display: 'flex', textAlign: 'center', justifyContent: 'start' }}>
            {' '}
            {/* <MonetizationOnIcon
              sx={{ fontSize: 20 }}
              color='success'
            /> */}
            <Typography sx={{color:'#3CB9BD',fontWeight:600,fontSize:18}}variant='subtitle2'>${data.valorDolar}</Typography>
          </Box>
        </Box>
      ) : (
        <Tooltip
          title={'Cotización del dolar divisa BNA vendedor del día ' + data?.fechaDolar}
          placement='right'
          describeChild={false}
        >
          <Box sx={{ display: 'flex', alignContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <MonetizationOnIcon
              sx={{ fontSize: 18 }}
              color='success'
            />
            <Typography variant='subtitle2'> ${data?.valorDolar}</Typography>
          </Box>
        </Tooltip>
      )}
    </>
  );
}
