import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormCalendar from '../../../components/form/FormCalendar';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
const OrdersForm = (props) => {
  const [orderNumber, setOrderNumber] = useState({
    query: '&SOPNUMBE=',
    filter: '',
  });
  const [state, setState] = useState({
    query: '&StateCode=',
    filter: '0',
    //filter:'iniciado'
  });

  const [PurchaseOrder, setPurchaseOrder] = useState({
    query: '&PurchaseOrder=',
    filter: '',
  });
  const [startDate, setStartDate] = useState({
    query: '&StartDate=',
    filter: '',
  });
  const [startDate2, setStartDate2] = useState({
    query: '&StartDate2=',
    filter: '',
  });

  const [resetCalendar,setResetCalendar] = useState(false)
  const handleFilter = props.function;
  const handlerResetFilter = props.handlerResetFilter;

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const allFilters = [orderNumber, state, PurchaseOrder, startDate, startDate2];
    handleFilter(allFilters);
  };

  const resetForm = () => {
    setOrderNumber({
      query: '&SOPNUMBE=',
      filter: '',
    });
    setState({
      query: '&StateCode=',
      filter: '',
    });
    setPurchaseOrder({
      query: '&PurchaseOrder=',
      filter: '',
    });
    setStartDate({
      query: '&StartDate=',
      filter: '',
    });
    setStartDate2({
      query: '&StartDate2=',
      filter: '',
    });
    setResetCalendar((prevState)=>!prevState)


    handlerResetFilter();
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Stack
          direction='column'
          spacing={1}
          alignItems='baseline'
          sx={{ minWidth: 4 }}
          width={'97%'}
        >
          <Grid
            padding={0}
            margin={0}
            sx={{ minWidth: '100%' }}
            container
            direction='column'
            xs={12}
          >
            <Grid
              xs={9}
              sx={{ minWidth: '100%' }}
              alignItems='flex-start'
              marginBottom={1.5}
            >
              <FormControl fullWidth>
                <TextField
InputLabelProps={{

    sx:{
    fontWeight:300,
    fontSize:14
  }}}
sx={{ backgroundColor: 'white' }}                 
                  id='outlined-required'
                  value={PurchaseOrder.filter}
                  label='Orden de compra'
                  defaultValue=''
                  onChange={(e) => setPurchaseOrder((prevState) => ({ ...prevState, filter: e.target.value }))}
                />
              </FormControl>
            </Grid>
            <Grid
              xs={9}
              alignItems='flex-start'
              sx={{ minWidth: '100%' }}
              marginBottom={1.5}
            >
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>Estado</InputLabel>
                <Select
            
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  label='Numero de pedido'
                  value={(state.filter === 'iniciado') ? '0' : state.filter}
                  onChange={(e) => setState((prevState) => ({ ...prevState, filter: e.target.value }))}
                  sx={{ backgroundColor: 'white', fontWeight:300,
                  fontSize:14 }}
                >
                  <MenuItem value={'0'}>Abierto</MenuItem>
                  <MenuItem value={'1'}>Cerrado</MenuItem>
                    
                </Select>
              </FormControl>
            </Grid>

            <Grid sx={{ minWidth: '100%' }}>
              <Grid sx={{ minWidth: '100%' }}>
                <Grid sx={{ minWidth: '100%' }}>
                  <Grid
                    marginBottom={1.5}
                    sx={{ minWidth: '100%' }}
                  >
                    <FormControl fullWidth>
                      <FormCalendar label={'Desde'} setStateValue={setStartDate} useStateValue={startDate} resetCalendar={resetCalendar} />
                    </FormControl>
                  </Grid>
                  <Grid
                    sx={{ minWidth: '80%' }}
                    marginTop={1.5}
                  >
                    <FormControl fullWidth>
                      <FormCalendar 
            
                      label={'Hasta'}  setStateValue={setStartDate2} useStateValue={startDate2} resetCalendar={resetCalendar}/>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  xs={9}
                  sx={{ minWidth: '100%' }}
                  alignItems='flex-start'
                  marginTop={1.5}
                >
                  <FormControl fullWidth>
                    <TextField
                    InputLabelProps={{

                      sx:{
                      fontWeight:300,
                      fontSize:14
                    }}}
                      sx={{ backgroundColor: 'white' }}
                      value={orderNumber.filter}
                      onChange={(e) => setOrderNumber((prevState) => ({ ...prevState, filter: e.target.value }))}
                      id='numeroPedido'
                      label='Numero de pedido'
                    />
                  </FormControl>
                </Grid>{' '}
              </Grid>
              <Grid
                marginTop={3}
                spacing={0}
                container
                direction='column'
                justifyContent='center'
                alignItems='stretch'
                xs={12}
              >
                <Button
                  variant='contained'
                  type='submit'
                  sx={{backgroundColor:'#3CB9BD',color:'white', '&:hover': {backgroundColor: '#3CB9BD',color:'white'}}}

                >
                  Filtrar
                </Button>
                <Button
            sx={{marginTop:3,backgroundColor:'#FF9636',color:'white'
           , '&:hover': {backgroundColor: '#FF9636',color:'white'}}
          }
            size='large'
                  variant='contained'
                  onClick={resetForm}

                >
                  Quitar filtros
                </Button>
               
              </Grid>{' '}
            </Grid>
          </Grid>
        </Stack>
      </form>
    </>
  );
};

export default OrdersForm;
