import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { userContext } from '../../context/userContext';
export default function ColorToggleButton() {
  const { company, handleUpdateCompany } = React.useContext(userContext);

  const handleChange = (event, newAlignment) => {
    if (newAlignment === null) {
      return;
    }
    handleUpdateCompany(newAlignment);
  };

  return (
    <ToggleButtonGroup
      sx={{
        '& .MuiToggleButton-root:hover': {
          backgroundColor: '#3CB9BD',
          color: '#f1f1f1',
        },
        '& .Mui-selected': {
          backgroundColor: '#0D6878',
          color: '#f1f1f1',
        },
        //Aria pressed
        '& .MuiToggleButton-root.Mui-selected:hover': {
          backgroundColor: '#3CB9BD',
          color: '#f1f1f1',
        },
      }}
      value={company}
      exclusive
      onChange={handleChange}
      aria-label='Platform'
    >
      <ToggleButton value='CORTE'>Corte Chap S.A</ToggleButton>
      <ToggleButton value='CCHAP'>Unipersonal</ToggleButton>
    </ToggleButtonGroup>
  );
}
