import React, {  useContext } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import EvolutionStackedChartLine from './EvolutionStackedChartLine';
import HomeCollapsibleTable from './HomeCollapsibleTable';
import HomeCalendar from './HomeCalendar';
import ChildCard from '../../../components/card/ChildCard';
import { getQuantities } from '../../../services/ordersServices';
import { userContext } from '../../../context/userContext';
import { Skeleton } from '@mui/material';
import { useQuery } from '@tanstack/react-query'
import PillsCard from '../../../components/card/PillsCard';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import {useTheme} from '@mui/material/styles'
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import BalanceIcon from '@mui/icons-material/Balance';
import ScheduleIcon from '@mui/icons-material/Schedule';
export default function Home() { //
  const theme = useTheme()
  const { userID, instance, accounts,company } = useContext(userContext);
  const { isLoading, isError, data } = useQuery({
    queryKey: ['TotalQuantity', 'PendingQuantity', 'OpenOrders','AssignedQuantity',company,userID],
    queryFn: () =>  getQuantities(userID, instance, accounts,company),
  },{
    cacheTime: 60000 
  })

 
  if (isError) {return <div><h3>Algo ha fallado..</h3></div>  }

  return (
    <>
      <Box sx={{ minHeight: '100vh' }}>   
          <Grid
            container
            justifyContent='space-around'
            
            columnGap={2}
          >
            <Grid sx={{display:'flex',justifyContent:'space-between' }} xs={5} columnGap={2}> 
           <PillsCard descriptionColor={'#FF9636'} icon={<LocalShippingIcon sx={{color:'white',fontSize:25}}/>}title={"Pendientes de Entrega"} description={ 
              isLoading ? (
                <Skeleton
                  variant='text'
                  width={50}
                /> ): ( Number(data?.PendingQuantity / 1000)
                    .toFixed(2)
                    .replace('.', ',') + ' tns')}/>
             <PillsCard descriptionColor={'#FF9636'}  icon={<PendingActionsIcon sx={{color:'white',fontSize:28}} />} title={"Pedidos Abiertos"} description={
               isLoading ? (
                <Skeleton
                  variant='text'
                  width={50}
                /> ): data?.OpenOrders}/>
                </Grid>
                <Grid sx={{display:'flex',justifyContent:'space-between' }}   xs={5} columnGap={2}>
             <PillsCard descriptionColor={'#6E726E'}  icon={<BalanceIcon sx={{color:'white',fontSize:28}}/>} title={"Saldo de Cuenta Corriente"} description={ 'Próximamente'}/>
             <PillsCard descriptionColor={'#6E726E'}  icon={<ScheduleIcon sx={{color:'white',fontSize:28}}/> }title={"Comprobantes vencidos"} description={ 'Próximamente'}/>
             </Grid>
          </Grid>
          <Grid
            container
            marginTop='10px'
            marginBottom={'10px'}
           justifyContent={'space-around'}
            xs={12}
           columnGap={2}
          >
        <Grid xs={5} >
              <ChildCard
                title={'EVOLUCION DE DESPACHOS'}
                subheader={'Toneladas por mes'}
                color={theme.palette.card.green}
              >
                {' '}
                
                <EvolutionStackedChartLine />
              </ChildCard>
              </Grid>
              <Grid xs={5}>

              <ChildCard
                title={'AGENDA'}
                subheader={'Calendario de Pedidos y Eventos'}
                color={theme.palette.card.darkGreen}

              >
                {' '}
                <HomeCalendar  />
              </ChildCard>
              </Grid>

          </Grid>

          <Grid
            container
            marginTop='10px'
            justifyContent={'center'}
           alignItems={'center'}
            xs={12}
          >
            
            <Grid xs={11.07}>
              <ChildCard
                title={'PEDIDOS ABIERTOS'}
                //subheader={'Detalles'}
                padding={0}
                paddingContent={0}
                paddingBottom={0}
                color={theme.palette.card.orange}

              >
                {' '}
                <HomeCollapsibleTable />{' '}
              </ChildCard>{' '}
              </Grid>
          </Grid>
      </Box>
    </>
  );
}
