import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InvoicesFilteringTable from './InvoicesFilteringTable';


export default function Invoices(props) {
  const userID = props.accounts;

  
  
  return (
    <Box sx={{ minHeight: '100vh' }}>
      {' '}
     
      <Grid
        padding={0}
        margin={0}
        container
        xs={12}
      >
      <InvoicesFilteringTable userID={userID} />
      </Grid>
    </Box>
  );
}
