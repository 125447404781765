import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Orders from '../views/Authenticate/orders/Orders';
import Home from '../views/Authenticate/home/Home';
import MiniDrawer from '../components/drawer/MiniDrawer';
import { useMsal } from '@azure/msal-react';
import ErrorPage from '../views/Authenticate/404/ErrorPage';
import Admin from '../views/Authenticate/admin/Admin';
import Customer from '../views/Authenticate/customer/Customer';
import { userContext } from '../context/userContext';
import Invoices from '../views/Authenticate/invoices/Invoices';
import CorteUtilities from '../views/Authenticate/corteUtilities/CorteUtilities';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import BlankPage from '../views/Authenticate/blankpage/BlankPage';
import AccountBalance from '../views/Authenticate/accountBalance/AccountBalance';
import UnauthenticationTemplateMessage from '../views/unauthenticate/unauthenticationTemplateBody/UnauthenticationTemplateMessage';
function RoutesProject() {
  const { instance, accounts } = useMsal();
  const queryClient = new QueryClient();
  const [company, setCompany] = useState('CORTE');
  const [userID, setUserID] = React.useState(accounts[0]?.idTokenClaims.extension_AccountID);
  const [adminUser] = React.useState(() => {
    const email = accounts[0]?.idTokenClaims.emails[0];
    return  (email.includes('@eucariota.eu')  || email.includes('@cortechap.com.ar'))
  });

  if (accounts.length > 0) {
    var homeAccountId = accounts[0].homeAccountId;
    // var userID = accounts[0].idTokenClaims.extension_AccountID;
    var username = accounts[0].username;
    var givenName = accounts[0].idTokenClaims.given_name;
    var familyName = accounts[0].idTokenClaims.family_name;
  }
  const handleUpdateUserID = (nuevoValor) => {
    setUserID(nuevoValor);
  };

  const handleUpdateCompany = (nuevoValor) => {
    setCompany(nuevoValor);
  };


  return (
   userID? (
    <userContext.Provider
      value={{
        givenName,
        familyName,
        userID,
        homeAccountId,
        username,
        instance,
        accounts,
        company,
        handleUpdateUserID,
        handleUpdateCompany,
        adminUser
            }}
    >
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route
              path='/'
              element={
                <MiniDrawer
                  title={'Inicio'}
                  homeAccountId={homeAccountId}
                >
                  <Home accounts={userID} />
                </MiniDrawer>
              }
            />
          
            <Route
              path='/orders'
              element={
                <MiniDrawer title={'Pedidos'}>
                  <Orders accounts={userID} />
                </MiniDrawer>
              }
            />
            <Route
              path='/invoices'
              element={
                <MiniDrawer title={'Comprobantes'}>
                  <Invoices accounts={userID} />
                </MiniDrawer>
              }
            />
            <Route
              path='/customers'
              element={
                <MiniDrawer title={'Perfil'}>
                  <Customer accounts={userID} />
                </MiniDrawer>
              }
            />
            <Route
              path='/error'
              element={
                <MiniDrawer title={'Perfil'}>
                  <ErrorPage />
                </MiniDrawer>
              }
            />
           
            <Route
              path='/utilities'
              element={
                <MiniDrawer title={'Utilidades'}>
                  <CorteUtilities />
                </MiniDrawer>
              }
            />
           
            <Route
              path='/admin'
              element={
                <MiniDrawer title={'Admin'}>
                  <Admin />
                </MiniDrawer>
              }
            />
             <Route
              path='/blankpage'
              element={
                <MiniDrawer title={'Admin'}>
                  <BlankPage />
                </MiniDrawer>
              }
            />
            <Route
              path='/estadocuenta'
              element={
                <MiniDrawer title={'Estado de Cuenta'}>
                  <AccountBalance />
                </MiniDrawer>
              }
            />
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </userContext.Provider>
     ) : <UnauthenticationTemplateMessage userID={userID}/>
  );
}

export default RoutesProject;
