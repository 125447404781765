import React, { useContext } from 'react';
import FullCalendar from '@fullcalendar/react';
import esLocale from '@fullcalendar/core/locales/es';
import listPlugin from '@fullcalendar/list';
import { userContext } from '../../../context/userContext';
import './styleHomeCalendar.css';
import { getAgenda } from '../../../services/agendaServices';
import { useQuery } from '@tanstack/react-query';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
const feriados = [
  {
    title: 'Feriado Puente Turístico',
    start: '2022-12-09',

  },
  {
    title: 'Navidad',
    start: '2022-12-25',

  },
  {
    title: 'Feriado de Carnaval',
    start: '2023-02-20',

  },
  {
    title: 'Feriado de Carnaval',
    start: '2023-02-21',

  },
  {
    title: 'Día Nacional de la Memoria por la Verdad y la Justicia',
    start: '2023-03-24',

  },
  {
    title: 'Día del Veterano y de los Caídos en la Guerra de Malvinas',
    start: '2023-04-02',

  },
  {
    title: 'Viernes Santo',
    start: '2023-04-07',

  },
  {
    title: 'Día del Trabajador',
    start: '2023-05-01',

  },
  {
    title: 'Día de la Revolución de Mayo',
    start: '2023-05-25',

  },
  {
    title: 'Feriado Puente Turístico',
    start: '2023-05-26',

  },
  {
    title: 'Paso a la Inmortalidad del Gral. Don Martín Miguel de Güemes',
    start: '2023-06-17',

  },
  {
    title: 'Feriado Puente Turístico',
    start: '2023-06-19',

  },
  {
    title: 'Paso a la Inmortalidad del General Manuel Belgrano',
    start: '2023-06-20',

  },
  {
    title: 'Día de la Independencia',
    start: '2023-07-09',

  },
  {
    title: 'Paso a la Inmortalidad del Gral. José de San Martín (17/8)',
    start: '2023-08-21',

  },
  {
    title: 'Año nuevo Judío',
    start: '2023-09-16',

  },
  {
    title: 'Año nuevo Judío',
    start: '2023-09-17',

  },
  {
    title: 'Día del Perdón',
    start: '2023-09-25',

  },
  {
    title: 'Feriado Puente Turístico',
    start: '2023-10-13',

  },
  {
    title: 'Día del Respeto a la Diversidad Cultural (12/10)',
    start: '2023-10-16',

  },
  {
    title: 'Día de la Soberanía Nacional',
    start: '2023-11-20',

  },
  {
    title: 'Año nuevo Judío',
    start: '2024-01-01',

  },
];
export default function HomeCalendar() {
  const { userID, instance, accounts,company } = useContext(userContext);


  const { data, isError } = useQuery({
    queryKey: ['title', 'start', userID, company],
    queryFn: () => getAgenda(userID, instance, accounts, company),
    select: (data) => {
      if (!data) {
        return feriados;
      } else {
        // 🔹 1. Convertir fechas solo si es necesario
        const formattedEvents = data.map(event => ({
          ...event,
          start: event.start.includes('T') ? event.start.split('T')[0] : event.start
        }));
  
        // 🔹 2. Filtrar duplicados usando un Map para asegurarse de que un mismo evento no se repita
        const uniqueEvents = new Map();
        formattedEvents.forEach(event => {
          const key = `${event.start}_${event.title}`; // Clave única
          if (!uniqueEvents.has(key)) {
            uniqueEvents.set(key, event);
          }
        });
  
        return Array.from(uniqueEvents.values());
      }
    }
  }, {
    cacheTime: 60000
  });
  

  if (isError) { return <div><h4>Algo ha fallado..</h4></div>  }

function renderEventContent(eventInfo) {
  const isTruckEvent = eventInfo.event.title.includes("KG");

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
   
      <span>{eventInfo.event.title}</span>
      {isTruckEvent && (
        <LocalShippingIcon style={{ color: '#FF9636', marginLeft: '10px' }} />
      )}
    </div>
  );
}

  
  
  return (
    <FullCalendar
    displayEventTime={false}
    height={390}
    locale={esLocale}
    plugins={[listPlugin]}
    initialView="listMonth"
    views={{
      listDay: { buttonText: 'Día' },
      listWeek: { buttonText: 'Semana' },
      listMonth: { buttonText: 'Mes' },
    }}
    headerToolbar={{
      left: 'title',
      center: '',
      right: 'listDay,listWeek,listMonth',
    }}
    weekends={true}
    events={data}
    eventDisplay="list-item" // 🔹 Evita la bolita por defecto
    eventContent={renderEventContent} // 🔹 Personaliza el contenido
  />
  
  );
}
