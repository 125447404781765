import axios from 'axios';

function getToken(instance, accounts) {
  return instance
    .acquireTokenSilent({
      scopes: [
        'https://cortechapb2c.onmicrosoft.com/corte-chap-api/orders.read',
        'https://cortechapb2c.onmicrosoft.com/corte-chap-api/invoices.read',
      ],
      account: accounts[0],
    })
    .then((response) => {
      return response.accessToken;
    });
}

export async function getLastDollar(instance, accounts) {
  const accessToken = await getToken(instance, accounts);
  const config = {
    headers: {
      'Cache-Control': 'max-age=31536000',

      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios
    .get(`${process.env.REACT_APP_API_URL}/utilities/currency/last`, config)
    .then((res) => {
      return res;
    });
}


export async function getListDollar(instance, accounts) {
  const accessToken = await getToken(instance, accounts);

console.log('el token ',accessToken)
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios
    .get(`${process.env.REACT_APP_API_URL}/utilities/currency`,config)
    .then((res) => {return res;})}
