import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InvoiceFormCalendar from './InvoiceFormCalendar';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
const InvoiceFormOrders = (props) => {
  const [orderNumber, setOrderNumber] = useState({
    query: '&SOPNUMBE=',
    filter: '',
  });
  const [state, setState] = useState({
    query: '&SOPNUMBEType=',
    filter: '',
  });

  const [PurchaseOrder, setPurchaseOrder] = useState({
    query: '&PurchaseOrder=',
    filter: '',
  });
  const [startDate, setStartDate] = useState({
    query: '&StartDate=',
    filter: '',
  });
  const [startDate2, setStartDate2] = useState({
    query: '&StartDate2=',
    filter: '',
  });

  const [resetCalendar,setResetCalendar] = useState(false)
  const handleFilter = props.function;
  const handlerResetFilter = props.handlerResetFilter;

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const allFilters = [orderNumber, state, PurchaseOrder, startDate, startDate2];
    handleFilter(allFilters);
  };

  const resetForm = () => {
    setOrderNumber({
      query: '&SOPNUMBE=',
      filter: '',
    });
    setState({
      query: '&SOPNUMBEType=',
      filter: '',
    });
    setPurchaseOrder({
      query: '&PurchaseOrder=',
      filter: '',
    });
    setStartDate({
      query: '&StartDate=',
      filter: '',
    });
    setStartDate2({
      query: '&StartDate2=',
      filter: '',
    });
    setResetCalendar((prevState)=>!prevState)


    handlerResetFilter();
  };
  // const handleChange = (event) => {
  //   setAge(event.target.value);
  // };
  return (
    <>
      <form onSubmit={handleSubmit}>
        <Stack
          direction='column'
          spacing={1}
          alignItems='baseline'
          sx={{ minWidth: 4 }}
          width={'90%'}
        >
          <Grid
            padding={0}
            margin={0}
            height={'100%'}
            sx={{ minWidth: '100%' }}
            container
            direction='column'
            xs={12}
          >
            {/* <Grid
              xs={9}
              sx={{ minWidth: '100%' }}
              alignItems='flex-start'
              marginBottom={1.5}
            > */}
              {/* <FormControl fullWidth>
                <TextField
                  sx={{ backgroundColor: 'white' }}
                  id='outlined-required'
                  value={PurchaseOrder.filter}
                  label='Orden de compra'
                  defaultValue=''
                  onChange={(e) => setPurchaseOrder((prevState) => ({ ...prevState, filter: e.target.value }))}
                />
              </FormControl> */}
            {/* </Grid> */}
            <Grid
              xs={9}
              alignItems='flex-start'
              sx={{ minWidth: '100%' }}
              marginBottom={1.5}
            >
              <FormControl fullWidth>
                <InputLabel
                     sx={{ backgroundColor: 'white', fontWeight:300,
                     fontSize:14 }}
                id='demo-simple-select-label' >Tipo de comprobante</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  label='Numero de pedido'
                  value={state.filter}
                  onChange={(e) => setState((prevState) => ({ ...prevState, filter: e.target.value }))}
                  sx={{ backgroundColor: 'white', fontWeight:300,
                  fontSize:14 }}
                >
                  <MenuItem value={'NCA'}>NC</MenuItem>
                  <MenuItem value={'NDA'}>ND</MenuItem>
                  <MenuItem value={'FCA'}>Factura</MenuItem>

                </Select>
              </FormControl>
            </Grid>

            <Grid sx={{ minWidth: '100%' }}>
              <Grid sx={{ minWidth: '100%' }}>
                <Grid sx={{ minWidth: '100%' }}>
                  <Grid
                    marginBottom={1.5}
                    sx={{ minWidth: '100%' }}
                  >
                    <FormControl fullWidth>
                      <InvoiceFormCalendar label={'Desde'} setStateValue={setStartDate} useStateValue={startDate} resetCalendar={resetCalendar} />
                    </FormControl>
                  </Grid>
                  <Grid
                    sx={{ minWidth: '80%' }}
                    marginTop={1.5}
                  >
                    <FormControl fullWidth>
                      <InvoiceFormCalendar label={'Hasta'}  setStateValue={setStartDate2} useStateValue={startDate2} resetCalendar={resetCalendar}/>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  xs={9}
                  sx={{ minWidth: '100%' }}
                  alignItems='flex-start'
                  marginTop={1.5}
                >
                  <FormControl fullWidth>
                    <TextField
                     InputLabelProps={{

                      sx:{
                      fontWeight:300,
                      fontSize:14
                    }}}
                      sx={{ backgroundColor: 'white' }}
                      value={orderNumber.filter}
                      onChange={(e) => setOrderNumber((prevState) => ({ ...prevState, filter: e.target.value }))}
                      id='numeroPedido'
                      label='Nro de comprobante'
                      
                      
                    />
                  </FormControl>
                </Grid>{' '}
              </Grid>
              <Grid
                marginTop={3}
                spacing={0}
                container
                direction='column'
                justifyContent='center'
                alignItems='stretch'
                xs={12}
              >
                <Button
                  size='large'
                  variant='contained'
                  type='submit'
                  sx={{backgroundColor:'#3CB9BD',color:'white', '&:hover': {backgroundColor: '#3CB9BD',color:'white'}}}

                >
                  Filtrar
                </Button>
                <Button
        sx={{marginTop:3,backgroundColor:'#FF9636',color:'white'
        , '&:hover': {backgroundColor: '#FF9636',color:'white'}}
       }            size='large'
                  variant='contained'
                  onClick={resetForm}
                  
                >
                  Quitar filtros
                </Button>
                {/* <Button
                     sx={{ marginTop: 3 }}
                     size='large'
                     variant='contained'
                     onClick={testPDF}>
                  Get pdf
                </Button> */}
              </Grid>{' '}
            </Grid>
          </Grid>
        </Stack>
      </form>
    </>
  );
};

export default InvoiceFormOrders;
