import axios from 'axios';

function getToken(instance, accounts) {
  return instance
    .acquireTokenSilent({
      scopes: [
        'https://cortechapb2c.onmicrosoft.com/corte-chap-api/orders.read',
        'https://cortechapb2c.onmicrosoft.com/corte-chap-api/invoices.read',
      ],
      account: accounts[0],
    })
    .then((response) => {
      return response.accessToken;
    });
} 

export async function getRowsTableHome(instance, accounts, userID,company) {
  const accessToken = await getToken(instance, accounts);

  const config = {
    headers: {
      'Cache-Control': 'max-age=31536000',
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios
  .get(`${process.env.REACT_APP_API_URL}/orders/detailed?CUSTNMBR=${userID}&Company=${company}&StateCode=0`, config)

  //  .get(`http://localhost:8080/v1/orders/detailed?CUSTNMBR=${userID}&StateCode=0`, config)
    .then((response) => {
      return response;
    });
}


export async function getQuantities(userID, instance, accounts,company) {
  const accessToken = await getToken(instance, accounts);

  const config = {
    headers: {
      'Cache-Control': 'max-age=31536000',
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return (
    axios
   .get(`${process.env.REACT_APP_API_URL}/orders/quantities?CUSTNMBR=${userID}&STATE=0&Company=${company}`, config)

      //  .get(`https://test-backend-cortechap.azurewebsites.net/v1/orders/quantities?CUSTNMBR=${userID}&STATE=0`, config)
      .then((res) => {
        console.log(res.data.data[0])
        return res.data.data[0];
      })
  );
}

export async function getPDFView(instance, accounts, invoiceNumber,userID) {
  const accessToken = await getToken(instance, accounts);

  const config = {
    responseType: 'blob',
    headers: {
      'content-type': 'application/pdf',
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return axios
  .get(`${process.env.REACT_APP_API_URL}/invoices/documents/${invoiceNumber.trim()}?CUSTNMBR=${userID}`, config)

    // .get(`https://test-backend-cortechap.azurewebsites.net/v1/invoices/documents/${invoiceNumber.trim()}`, config)
    .then((res) => {
      const blob = new Blob([res.data], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      return url;
    });
}


export async function downloadPDF(instance, accounts, invoiceNumber,userID) {
  const accessToken = await getToken(instance, accounts);
  const config = {
    responseType: 'blob',
    headers: {
      'content-type': 'application/pdf',
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return axios
  .get(`${process.env.REACT_APP_API_URL}/invoices/documents/${invoiceNumber.trim()}?CUSTNMBR=${userID}`, config)
    // .get(`https://test-backend-cortechap.azurewebsites.net/v1/invoices/documents/${invoiceNumber.trim()}`, config)
    .then((res) => {
      const documentName = invoiceNumber.trim() + '.pdf';
      const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', documentName);
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      console.log(error.response.data);
    });
}



export async function postOrderReport (instance, accounts, userID,company,row) {
  const accessToken = await getToken(instance, accounts);
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'max-age=31536000',
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios
  //  .post(`http://localhost:8080/v1/orders/${row.SOPNUMBE}/report`, JSON.stringify(row),{
  .post(`${process.env.REACT_APP_API_URL}/orders/${row.SOPNUMBE.trim()}/report`, JSON.stringify(row),{
    headers: config.headers
  })
}