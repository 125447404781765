import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/es-mx';
import dayjs from 'dayjs';
export default function InvoiceFormCalendar( props ) {
  const { locale } = useState('es-mx');
  const [selectedDate, setSelectedDate] = useState(null);

const handleResetDate = () => {
  setSelectedDate(null);
}
  
useEffect(() => {
    handleResetDate();

}, [props.resetCalendar]);

  return (
    <LocalizationProvider 
      dateAdapter={AdapterDayjs}
      adapterLocale={locale}  
    >
      <DatePicker
      inputFormat="DD/MM/YYYY"
        label={props.label}
        value={selectedDate}
                  //eslint-disable-next-line
        onChange={val => {{
          props.setStateValue((prevState) => ({ ...prevState, filter: dayjs(val.$d).format('YYYY-MM-DD') }))};
          setSelectedDate(val)
        }}
          
        renderInput={(params) => (
          <TextField
          InputLabelProps={{

            sx:{
            fontWeight:300,
            fontSize:14
          }}}
            sx={{ minWidth: '90%', backgroundColor: 'white' }}
            {...params}
          />
        )}
      />
    </LocalizationProvider>
  );
}
