import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { CustomerDetails } from './CustomerDetails';
import { CustomerProfile } from './CustomerProfile';
import { Skeleton } from '@mui/material';
import { getCustomerProfile } from '../../../services/customerServices';

import { userContext } from '../../../context/userContext';
import { useQuery } from '@tanstack/react-query';
export default function Customer() {
  const { userID, instance, accounts } = useContext(userContext);
  const { isError, data } = useQuery(
    {
      queryKey: ['AccountGUID', userID],
      queryFn: () => getCustomerProfile(userID, instance, accounts),
    },
    {
      cacheTime: 60000, // cache for 1 minute
    }
  );

  if (isError) {
    return (
      <div>
        {' '}
        <h3>Algo ha fallado..</h3>{' '}
      </div>
    );
  }

  return (
    <>
      {data ? (
        <Box sx={{ minHeight: '88vh' }}>
          <Grid
            xs={12}
            container
            spacing={1}
          >
            <Grid
             display='flex'
             height={256}
             // alignItems='stretch'
              xs={3}
            >
              <CustomerProfile data={data} />
            </Grid>
            <Grid xs={9}>
              {' '}
              <CustomerDetails
                data={data}
                context={accounts}
              />
            </Grid>
          </Grid>{' '}
        </Box>
      ) : (
        <Box sx={{ minHeight: '88vh' }}>
          <Grid
            xs={12}
            sx={{ display: 'flex' }}
            container
            spacing={1}
          >
            <Grid
              display='flex'
              alignItems='center'
              justifyContent='center'
              container
              xs={3}
            >
              <Skeleton
                variant='circular'
                sx={{ height: '130px', width: '130px', padding: 0, margin: 0 }}
              />
              <Grid>
                <Skeleton sx={{ height: '150px', width: '250px', padding: 0, margin: 0 }} />
              </Grid>
            </Grid>
            <Grid
              xs={9}
              sx={{ padding: 0, margin: 0 }}
            >
              {' '}
              <Skeleton sx={{ width: '100%', height: '400px', padding: 0, margin: 0 }} />
              {/* <CustomerDetails customer={customer} /> */}
            </Grid>
          </Grid>{' '}
        </Box>
      )}
    </>
  );
}
