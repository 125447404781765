import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import OrdersFilteringTable from './OrdersFilteringTable';
export default function Orders() {

  return (
    <Box sx={{ minHeight: '100vh' }}>
      <Grid
        padding={0}
        margin={0}
        container
        xs={12}
      >
        <OrdersFilteringTable />
      </Grid>
    </Box>
  );
}
