import React from 'react';
import { loginRequest } from "../../../authConfig";
import { useMsal } from "@azure/msal-react";
import Bobinas2 from '../../../assets/Bobinas2.jpg';
import { Navigate } from 'react-router-dom';
import { useEffect } from 'react';

const UnauthenticationTemplateMessage = (props) => {
    const { instance,accounts } = useMsal();


    console.log(props)

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', margin: 0 }}>
            <header style={{
                flex: 1,
                backgroundImage: `url(${Bobinas2})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                color: 'white',
            }}>
<div style={{
  width: '50%',
  border: '1px solid #ddd', // Borde más delgado y color gris claro
  padding: '20px', // Espaciado interno
  background: '#f7f7f7', // Fondo ligeramente más claro
  borderRadius: '8px', // Bordes redondeados
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Sombra suave
  fontFamily: 'Arial, sans-serif', // Fuente moderna
  color: '#333', // Texto más oscuro
  lineHeight: '1.5', // Espaciado entre líneas
}}>
  <p style={{ color: '#333' }}> {/* Color de texto más oscuro */}
    Estimado cliente, su cuenta se ha generado correctamente. Dentro de las próximas 24 horas hábiles habilitaremos el acceso a su información, previa validación de los datos ingresados al momento del registro.
<br></br>
    Por cualquier consulta, puede comunicarse a portaldeclientes@cortechap.com.ar
  </p>
</div>
                
               
<button
  onClick={() => instance.loginRedirect(loginRequest)}
  style={{
    marginTop:'15px',
    padding: '12px 24px',
    backgroundColor: '#f0f0f0', // Color de fondo más claro
    color: '#333', // Color de texto
    border: '2px solid #333', // Borde sólido oscuro
    borderRadius: '5px', // Bordes redondeados
    cursor: 'pointer', // Cambia el cursor al pasar el ratón
    fontSize: '16px', // Tamaño de fuente
  }}
>
  Actualizar
</button>

            </header>
        </div>
    )
}

export default UnauthenticationTemplateMessage;
