import axios from 'axios';

function getToken(instance, accounts) {
  return instance
    .acquireTokenSilent({
      scopes: [
        'https://cortechapb2c.onmicrosoft.com/corte-chap-api/orders.read',
        'https://cortechapb2c.onmicrosoft.com/corte-chap-api/invoices.read',
      ],
      account: accounts[0],
    })
    .then((response) => {
      return response.accessToken;
    });
}

export async function getRowsTableOrders(instance, accounts, page, rowsPerPage, filter, company) {
  const accessToken = await getToken(instance, accounts);
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const filtered = () => {
    const valores = filter

      .map(function (item) {
        return item.query + item.filter;
      })
      .join('');
    return valores;
   };
  // const filtered = () => {
  //   const valores = filter
  //     .map(function (item) {
  //       if (item.query === '&StateCode=' ) {
  //         // Si es el filtro &StateCode= con valor 0, no lo incluimos en el resultado
  //         return '';
  //       } else {
  //         return item.query + item.filter;
  //       }
  //     })
  //     .join('');
  //   return valores;
  // };
  const result = filtered();
  return (
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/orders/detailed?${result}&Company=${company}&offset=${page * 10}&limit=${rowsPerPage}`, //&offset=${page * 10}&limit=${rowsPerPage}
        config
      ) // `&offset=${page * 10}&limit=${rowsPerPage}
      // .get(`${process.env.REACT_APP_API_URL}/orders/detailed?${result}&Company=${company}&offset=${(page) * 10}&limit=${rowsPerPage}`, config)
      .then((response) => {
        return response.data;
      })
  );
}

export async function getRowsTableInvoices(instance, accounts, page, rowsPerPage, filter, company) {
  const accessToken = await getToken(instance, accounts);
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const filtered = () => {
    const valores = filter
      .map(function (item) {
        return item.query + item.filter;
      })
      .join('');
    return valores;
  };

  const result = filtered();
  //&StartDate=2023-01-01&StartDate2=2023-05-05&
  return (
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/invoices?${result}&Company=${company}&offset=${
          page * 10
        }&limit=${rowsPerPage}`,
        config
      ) // `&offset=${page * 10}&limit=${rowsPerPage}
//
      //  .get(`https://test-backend-cortechap.azurewebsites.net/v1/invoices?${filters}&offset=${(page) * 10}&limit=${rowsPerPage}`, config) // `&offset=${page * 10}&limit=${rowsPerPage}
      .then((response) => {
        return response;
      })
  );
}
