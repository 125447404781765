import React, { useContext } from 'react';
import FullCalendar from '@fullcalendar/react';
import esLocale from '@fullcalendar/core/locales/es';
import listPlugin from '@fullcalendar/list';
import { userContext } from '../../../context/userContext';
import './styleHomeCalendar.css';
import { getAgenda } from '../../../services/agendaServices';
import { useQuery } from '@tanstack/react-query';

const feriados = [
  {
    title: 'Feriado Puente Turístico',
    start: '2022-12-09',

  },
  {
    title: 'Navidad',
    start: '2022-12-25',

  },
  {
    title: 'Feriado de Carnaval',
    start: '2023-02-20',

  },
  {
    title: 'Feriado de Carnaval',
    start: '2023-02-21',

  },
  {
    title: 'Día Nacional de la Memoria por la Verdad y la Justicia',
    start: '2023-03-24',

  },
  {
    title: 'Día del Veterano y de los Caídos en la Guerra de Malvinas',
    start: '2023-04-02',

  },
  {
    title: 'Viernes Santo',
    start: '2023-04-07',

  },
  {
    title: 'Día del Trabajador',
    start: '2023-05-01',

  },
  {
    title: 'Día de la Revolución de Mayo',
    start: '2023-05-25',

  },
  {
    title: 'Feriado Puente Turístico',
    start: '2023-05-26',

  },
  {
    title: 'Paso a la Inmortalidad del Gral. Don Martín Miguel de Güemes',
    start: '2023-06-17',

  },
  {
    title: 'Feriado Puente Turístico',
    start: '2023-06-19',

  },
  {
    title: 'Paso a la Inmortalidad del General Manuel Belgrano',
    start: '2023-06-20',

  },
  {
    title: 'Día de la Independencia',
    start: '2023-07-09',

  },
  {
    title: 'Paso a la Inmortalidad del Gral. José de San Martín (17/8)',
    start: '2023-08-21',

  },
  {
    title: 'Año nuevo Judío',
    start: '2023-09-16',

  },
  {
    title: 'Año nuevo Judío',
    start: '2023-09-17',

  },
  {
    title: 'Día del Perdón',
    start: '2023-09-25',

  },
  {
    title: 'Feriado Puente Turístico',
    start: '2023-10-13',

  },
  {
    title: 'Día del Respeto a la Diversidad Cultural (12/10)',
    start: '2023-10-16',

  },
  {
    title: 'Día de la Soberanía Nacional',
    start: '2023-11-20',

  },
  {
    title: 'Año nuevo Judío',
    start: '2024-01-01',

  },
];
export default function HomeCalendar() {
  const { userID, instance, accounts,company } = useContext(userContext);


  const { data, isError } = useQuery({
    queryKey: ['title', 'start', userID, company],
    queryFn: () => getAgenda(userID, instance, accounts, company),
    select: (data) => {
      if (data === undefined) {
        return feriados;
      } else {
        return data.map(event => ({
          ...event,
          start: new Date(event.start).toISOString().split('T')[0]
        }))

        //  feriados.concat(
        //   data.map(event => ({
        //     ...event,
        //     start: new Date(event.start).toISOString().split('T')[0]
        //   }))
        

      }
    }
  }, {
    cacheTime: 60000
  });

  if (isError) { return <div><h4>Algo ha fallado..</h4></div>  }


  return (
      <FullCalendar
        displayEventTime={false}
        height={390}
        locale={esLocale}
        
        plugins={[listPlugin]}
        initialView='listMonth'
        views={{
          listDay: { buttonText: 'Día' },
          listWeek: { buttonText: 'Semana' },
          listMonth: { buttonText: 'Mes' },
        }}
        headerToolbar={{
          left: 'title',
          center: '',
          right: 'listDay,listWeek,listMonth',
        }}
        weekends={true}
        events={data}
      />
  );
}
