import axios from 'axios';

function getToken(instance, accounts) {
  return instance
    .acquireTokenSilent({
      scopes: [
        'https://cortechapb2c.onmicrosoft.com/corte-chap-api/orders.read',
        'https://cortechapb2c.onmicrosoft.com/corte-chap-api/invoices.read',
      ],
      account: accounts[0],
    })
    .then((response) => {
      return response.accessToken;
    });
}

export async function getAgenda(userID, instance, accounts, company) {
  const accessToken = await getToken(instance, accounts);
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Cache-Control': 'max-age=31536000',
    },
  };

  const agendaPedidos = axios
    .get(`${process.env.REACT_APP_API_URL}/orders?CUSTNMBR=${userID}&StateCode=0&Company=${company}`, config)
    .then((response) => {
      return response.data.data.rows.map((row) => ({
        start: row.ScheduledDate,
        title: row.SOPNUMBE,
        backgroundColor: '#3CB9BD',
        borderColor: '#3CB9BD',
      }));
    })
    .catch((error) => {
      console.log('el error del calendar', error);
    });

  const agendaEventos = axios
    .get(`${process.env.REACT_APP_API_URL}/utilities/events`, config)
    .then((response) => {
      return response.data.data.map((row) => ({
        start: row.EventDate,
        title: row.Title,
        backgroundColor: 'white',
        borderColor: 'white',
      }));
    })
    .catch((error) => {
      console.log('el error del calendar', error);
    });

// Obtén la fecha actual
const fechaActual = new Date();

// Obtén el primer día del mes actual
const primerDiaDelMes = new Date(fechaActual.getFullYear(), fechaActual.getMonth(), 1);

// Obtén el último día del mes actual
const ultimoDiaDelMes = new Date(fechaActual.getFullYear(), fechaActual.getMonth() + 1, 0);

// Función para formatear una fecha en "yyyy/MM/dd"
function formatearFecha(fecha) {
  const año = fecha.getFullYear();
  const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
  const dia = fecha.getDate().toString().padStart(2, '0');
  return `${año}/${mes}/${dia}`;
}

const primerDiaDelMesFormateado = formatearFecha(primerDiaDelMes);
const ultimoDiaDelMesFormateado = formatearFecha(ultimoDiaDelMes);

console.log(`${process.env.REACT_APP_API_URL}/invoices?CUSTNMBR=${userID}&Company=${company}&DueDate=${primerDiaDelMesFormateado}&DueDate2=${ultimoDiaDelMesFormateado}`)
    const agendaInvoices = axios
    .get(`${process.env.REACT_APP_API_URL}/invoices?CUSTNMBR=${userID}&Company=${company}&DueDate=${primerDiaDelMesFormateado}&DueDate2=${ultimoDiaDelMesFormateado}`, config)
    .then((response) => {
      return response.data.data.rows.map((row) => ({
        start: row.DueDate,
        title: row.SOPNUMBE,
        backgroundColor: '#3CB9BD',
        borderColor: '#3CB9BD',
      }));
    })
    .catch((error) => {
      console.log('el error del calendar', error);
    });

  return Promise.all([agendaPedidos, agendaEventos,agendaInvoices])
    .then((values) => {
      return values[0].concat(values[1],values[2]);
    })
    .catch((error) => {
      console.log('el error del calendar', error);
    });
}

// export async function getEvents(instance,accounts) {
//   const accessToken = await getToken(instance, accounts);
//   const config = {
//     headers: {
//       Authorization: `Bearer ${accessToken}`,
//       'Cache-Control': 'max-age=31536000',
//     },
//   };

//   return axios

//   .get(`${process.env.REACT_APP_API_URL}/utilities/events`,config)

//   .then((response) => {
//     return response.data.data.rows.map((row) => ({
//       start: row.StartDate,
//       title: row.SOPNUMBE,
//       backgroundColor: 'green',
//       borderColor: 'green'}))
//     })

//     .catch((error) => {
//       console.log('El fetch de Events fallo: ',error);
//     })
// }
