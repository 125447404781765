import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
const TestCard = ({ icon,title, description, imageUrl,descriptionColor }) => {
  const theme = useTheme();

  const windowWidth = window.innerWidth;

  // Itera sobre los breakpoints y encuentra el que coincide con el ancho de la ventana
  let currentBreakpoint = null;

  for (const breakpoint in theme.breakpoints.values) {
    if (windowWidth >= theme.breakpoints.values[breakpoint]) {
      currentBreakpoint = breakpoint;
    }
  }
  console.log(windowWidth)
  console.log('Breakpoint actual:', currentBreakpoint);
  return (
    <Card
    sx={{
     // minWidth: 250,
      maxWidth: 280,
      width:'100%',
      maxHeight: 75,
   
      overflow: 'hidden',
      boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0.1)',
      transition: '0.3s',
      '&:hover': {
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
      },

    }}
  >
      <CardContent
        sx={{
          paddingTop:1.50,
          alignItems:'center',
          display:'flex',
          justifyContent:'space-between',

         // padding: (theme) => theme.spacing(2),
          backgroundColor: theme.palette.background.card,
       //   borderLeft: `4px solid ${theme.palette.background.header}`
        }}
      >
       <Box> <Typography
          sx={{
            fontSize: 14,
            color: '#3CB9BD',
            fontFamily:'encode sans'
          }}
          variant="body1"
          component="div"
        >
         {title}
        </Typography>
        <Typography
          sx={{ fontSize: 20,fontWeight:'bold',color: descriptionColor, fontFamily:'encode sans' }}
          variant="body2"
        >
          {description}
        </Typography></Box>
       
        <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:theme.palette.background.header,borderRadius:2, height:40,width:40}}>
        {icon}
        </Box>
      </CardContent>
    </Card>
  );
};

export default TestCard;