import React, { useContext } from 'react';
// import PropTypes from "prop-types";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableFooter from '@mui/material/TableFooter';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import InvoicesModal from './InvoicesModal';

import '../../../components/tables/styleCollapsibleActionTable.css';
import '../../../components/tables/styleTablePagination.css';
import { downloadPDF } from '../../../services/ordersServices';
import { userContext } from '../../../context/userContext';
function Row(props) {
  const { row,rowIndex,handler } = props;
  const [open] = React.useState(false);
  const { instance, accounts, userID } = useContext(userContext);
  return (
    <React.Fragment>
      <TableRow
        sx={{
          '& > *': { borderBottom: 'unset' },
          backgroundColor: open ? '#EDEDED' : rowIndex % 2 !== 0 ? 'white' : '#EDEDED',
          '&.MuiTableRow-root:hover': {
            backgroundColor: 'rgb(63, 81, 181,0.11)',
          },
        }} 
      > <TableCell
      sx={{ paddingRight: 0.1, paddingLeft: 0.5 ,color:'#6E726E',fontWeight:400}}
      align='center'
    >
      {format(utcToZonedTime(new Date(row.IssueDate), 'UTC'), 'dd/MM/yyyy')}
    </TableCell>
        <TableCell
          sx={{ paddingRight: 0.1, paddingLeft: 0.5,color:'#6E726E',fontWeight:600 }}
          align='center'
        >
          {row.SOPNUMBE.trim()}
        </TableCell>
       
        <TableCell
          sx={{ paddingRight: 0.1, paddingLeft: 0.5 ,color:'#6E726E',fontWeight:400}}
          align='center'
        >
          {format(utcToZonedTime(new Date(row.DueDate), 'UTC'), 'dd/MM/yyyy')}
        </TableCell>

        <TableCell
          sx={{ fontSize: 14, paddingLeft: 0.5, paddingRight: 0.5,color:'#6E726E',fontWeight:400 }}
          align='center'
        >
          $ {row.Subtotal}
        </TableCell>
        <TableCell
          sx={{ fontSize: 14, paddingLeft: 0.5, paddingRight: 0.5 ,color:'#6E726E',fontWeight:400}}
          align='center'
        >
          $ {row.TaxSubtotal}
        </TableCell>
        <TableCell
          sx={{ fontSize: 14, paddingLeft: 0.5, paddingRight: 0.5,color:'#6E726E',fontWeight:400 }}
          align='center'
        >
          $ {row.Total}
        </TableCell>
        <TableCell
          sx={{ paddingRight: 0.1, paddingLeft: 0.5,color:'#6E726E',fontWeight:400 }}
          align='center'
        >
          $ {row.ExchangeRate}
        </TableCell>
        <TableCell
          sx={{ paddingRight: 0.1, paddingLeft: 0.5,color:'#6E726E',fontWeight:400 }}
          align='center'
        >
          {row.PurchaseOrder}
        </TableCell>
        <TableCell
          sx={{ paddingRight: 0.1, paddingLeft: 0.5 ,color:'#6E726E',fontWeight:600}}
          align='center'
        >
          {row.OriginDoc}
        </TableCell>
        <TableCell
          align='center'
          sx={{ paddingLeft: 0.3, paddingRight: 0.3,color:'#6E726E',fontWeight:400 }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {row.SOPNUMBE.includes('FCA') && (
              <>
            <InvoicesModal
              instance={instance}
              accounts={accounts}
              userID={userID}
              invoiceNumber={row.SOPNUMBE.trim()}
              button={<VisibilityIcon fontSize='inherit' />}
            />
            <IconButton aria-label='delete'>
              <FileDownloadIcon
                onClick={() => downloadPDF(instance, accounts, row.SOPNUMBE, userID)}
sx={{color:'#3CB9BD'}}
                fontSize='inherit'
              />
            </IconButton></>)}
          </Box>
        </TableCell>

      </TableRow>
     
    </React.Fragment>
  );
}

export default function InvoiceCollapsibleActionTable({
  rows,
  loading,
  handleChangePage,
  handleChangeRowsPerPage,
  countRows,
  rowsPerPage,
  page,
})

{
  const [currentRowIdx, setCurrentRowIdx] = React.useState(-1);

  return (
    <TableContainer component={Paper}>
      <Table aria-label='collapsible table'>
        <TableHead style={{ margin: 0 }}>
          <TableRow
            sx={{ backgroundColor: '#3CB9BD'  }}
            style={{ padding: 0, margin: 0 }}
          >
                 <TableCell
              sx={{ color:'#D9E3EA',fontWeight:400, }}
              align='center'
            >
              F. Emisión
            </TableCell>
            <TableCell
              sx={{ color:'#D9E3EA',fontWeight:400, }}
              align='center'
            >
              Comprobante
            </TableCell>
       
            <TableCell
              sx={{ color:'#D9E3EA',fontWeight:400, }}
              align='center'
            >
              F. Vencimiento
            </TableCell>
          
            <TableCell
              sx={{ color:'#D9E3EA',fontWeight:400, }}
              align='center'
            >
              Subtotal
            </TableCell>

            <TableCell
              sx={{ color:'#D9E3EA',fontWeight:400, }}
              align='center'
            >
              Impuestos
            </TableCell>
            <TableCell
              sx={{ color:'#D9E3EA',fontWeight:400, }}
              align='center'
            >
              Total
            </TableCell>
            <TableCell
              sx={{ color:'#D9E3EA',fontWeight:400, }}
              align='center'
            >
              Tipo Cambio
            </TableCell>
            <TableCell
              sx={{ color:'#D9E3EA',fontWeight:400, }}
              align='center'
            >
              Notas
            </TableCell>
            <TableCell
              sx={{ color:'#D9E3EA',fontWeight:400, }}
              align='center'
            >
              Doc Relacionado
            </TableCell>
            <TableCell
              sx={{ color:'#D9E3EA',fontWeight:400, }}
              align='center'
            >
              Acciones
            </TableCell>
          </TableRow>
          {loading && (
            <TableRow align='center'>
              <TableCell
                align='center'
                colSpan={10}
              >
                {' '}
                <CircularProgress />{' '}
              </TableCell>
            </TableRow>
          )}
        </TableHead>

        <TableBody>
          {rows?.length > 0 &&
            !loading &&
            rows.map((row,index) => (
              <Row
                key={row.InvoiceGUID}
                row={row}
                rowIndex={index}
                currentRowIdx={currentRowIdx}
                setCurrentRowIdx={setCurrentRowIdx}
              />
            ))}
       
        </TableBody>
      </Table>

      <TableFooter colSpan={5}>
        <TableRow colSpan={5}>
          <TableCell
            sx={{ justifyContent: 'center' }}
            colSpan={5}
            align='right'
          >
            <TablePagination
              component='div'
              sx={{ pading: '0px', margin: '0px', display: 'flex', alignItems: 'baseline' }}
              count={countRows}
              rowsPerPageOptions={[5, 10, 25]}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              variant='outlined'
              size='large'
            />
          </TableCell>
        </TableRow>
      </TableFooter>
    </TableContainer>
  );
}
