import React from 'react';
import Grid from '@mui/material/Grid';
import InvoiceFormOrder from './InvoiceFormOrders';
import InvoiceCollapsibleActionTable from './InvoiceCollapsibleActionTable';
import reduceDigits from '../../../helper/reduceDigits';
import { userContext } from '../../../context/userContext';
import { getRowsTableInvoices } from '../../../services/filteredTablesServices';
import { useQuery } from '@tanstack/react-query';
export default function InvoicesFilteringTable({ userID }) {
  const { instance, accounts, company } = React.useContext(userContext);
  const [previousCount, setPreviousCount] = React.useState();

  const [filter, setFilter] = React.useState([
    {
      query: 'CUSTNMBR=',
      filter: userID,
    },
  ]);
  // const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(0);
  // const [countRows, setCountRows] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
 
const {
  isLoading,
  isError,
  data: { rows, count },
  error,
} = useQuery(
  {
    queryKey: [userID, company, filter, page, rowsPerPage],
    queryFn: () => getRowsTableInvoices(instance, accounts, page, rowsPerPage, filter, company),
    initialData: { rows: [], count: 0 },
    select: (data) => {
      const newCount = data?.data?.data?.count;
      if (newCount !== undefined && newCount !== previousCount) {
        setPreviousCount(newCount);
        setPage(0)
      }
      return {
        rows: reduceDigits(data?.data?.data?.rows),
        count: newCount !== undefined ? newCount : previousCount,
      };
    },
    onSuccess: (data) => {
      if (data === undefined) {
        setPage(0);
        setRowsPerPage(0);
      }
    },
  },
  {
    cacheTime: 0, // cache for 1 minute
  }
);

  if (isError) {
    return (
      <div>
        <h3>Algo ha fallado..</h3>
        <p>{error.message}</p> {/* Mostrar el mensaje de error */}
      </div>
    );
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handlerResetFilter = () => {
    setFilter([
      {
        query: 'CUSTNMBR=',
        filter: userID,
      },
    ]);
  };
  const handleFilter = (value) => {
    value.forEach((item1) => {
      const index = filter.findIndex((item) => item.query === item1.query);

      if (index > -1) {
        if (item1.filter === '') {
          const newFilter = [...filter];
          newFilter.splice(index, 1);
          return setFilter(newFilter);
        }
        if (filter[index].filter === item1.filter) return;

        //If value is different, replace it
        const newFilter = [...filter];
        newFilter[index] = item1;
        return setFilter(newFilter);
      }

      if (item1.filter === '') {
        return;
      }
      setFilter((prev) => [...prev, item1]);
    });
  };

  return (
    <>
      <Grid
        container
        columnGap={5}
        spacing={0.5}
        paddingTop={1}
        paddingBottom={2}
        justifyContent='end'
      ></Grid>
      <Grid
        sx={{ margin: 0, padding: 0 }}
        xs={2}
      >
        <InvoiceFormOrder
          props={{ userID, filter }}
          function={handleFilter}
          handlerResetFilter={handlerResetFilter}
        />
      </Grid>
      <Grid xs={10}>
        <InvoiceCollapsibleActionTable
          rows={rows}
          loading={isLoading}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          countRows={count}
          rowsPerPage={rowsPerPage}
          page={page}
        />
      </Grid>
    </>
  );
}
