import React from 'react';
import { MsalProvider } from '@azure/msal-react';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import RoutesProject from './routes';
import UnauthenticationTemplateBody from './views/unauthenticate//unauthenticationTemplateBody/unauthenticationTemplateBody';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { esES } from '@mui/material/locale';
import { clarity } from 'react-microsoft-clarity';
import './App.css';
// import {theme} from './theme/Theme'; // /
const theme = createTheme(
  {
    palette: {
      
      type: 'light',
      primary: {
      main: '#004D40' //Verde Cadbury
      //   main: '#0D6878', //Ocre
       
      },
      secondary: {
        main: '#f50057',
      },
      card:{
        orange: '#FF9636',
        green: '#3CB9BD',
        darkGreen: '#0D6878',
      },
      background: {
       default: '#EDEDED',
       header: '#0D6878', //Header superior
       

      //Originales
      //default: '#f1f1f1', //Fondo general
      //header:'#004D40', //Header superior
      //card:'rgb(63, 81, 181, 0.11)' //Relleno card
      }
    },
    typography: {
      // In Chinese and Japanese the characters are usually larger,
      // so a smaller fontsize may be appropriate.
      htmlFontSize: 16, // Tamaño de fuente base (1rem)
      fontSize: 14, // Tamaño de fuente por defecto (0.875rem)
      fontFamily: 'Encode Sans',
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,

      //original
      //fontFamily: 'Roboto ',
    },
    
  },
  esES
);
if (process.env.REACT_APP_NODE_CLARITY === "production") {
  clarity.init('modaeyqzaj');
}



console.log(process.env.REACT_APP_NODE_CLARITY)
export default function App({ msalInstance }) {
  return (
    <>
      <ThemeProvider theme={theme}>
        <MsalProvider instance={msalInstance}>
          <UnauthenticatedTemplate>
            <UnauthenticationTemplateBody />
          </UnauthenticatedTemplate>

          <AuthenticatedTemplate>
            <RoutesProject />
          </AuthenticatedTemplate>
        </MsalProvider>
      </ThemeProvider>
      
    </>
  );
}
