export function calculateOrderStep(props) {
    //FulfilledQuantity , Reserved , StateCode, StatusCode
      const { CancelledQuantity,AssignedQuantity,Reserved, WaitingQuantity,FulfilledQuantity, PendingQuantity,InvoicedQuantity, TotalQuantity = 0, Product, TotalQuantityAvailableStock = 0, Invoiced, ShippingMethod } = props;
   
      if(parseInt(TotalQuantity) === parseInt(CancelledQuantity)) { return 3} //cancelado

      //Se le aplico un round por el caso existente en DevOps task #2142 date 20-11-2023
    //  if (PendingQuantity === 0 && Math.round(InvoicedQuantity) === Math.round(TotalQuantity - CancelledQuantity)) { return 3 } //finalizado
    if (PendingQuantity === 0 && InvoicedQuantity === TotalQuantity - CancelledQuantity) { return 3 } //finalizado
   
      if (((Math.ceil(FulfilledQuantity) >0) || (Math.ceil(AssignedQuantity)) >0) && (AssignedQuantity != InvoicedQuantity)) {
        if(ShippingMethod === 'FLETE INCLUIDO'){return 2 //listo para carga
        }} //listo para retiro
        if ((Math.ceil(FulfilledQuantity) >0) || (Math.ceil(AssignedQuantity) >0) && (AssignedQuantity != InvoicedQuantity)) {
          if(ShippingMethod !== 'FLETE INCLUIDO'){return 2 //listo para retiro
          }}
        if (Reserved > 0) {
          return 1 //en proceso
        }
        if (Reserved <= 0) {
          return 0 //pedido nuevo
        }


    }