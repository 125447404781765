import React from 'react';
import Grid from '@mui/material/Grid';
import OrdersForm from './OrdersForm';
import OrdersCollapsibleActionTable from './OrdersCollapsibleActionTable';
import { userContext } from '../../../context/userContext';
import { getRowsTableOrders } from '../../../services/filteredTablesServices';
import { useQuery } from '@tanstack/react-query';
export default function OrdersFilteringTable() {
  const { instance, accounts, company, userID } = React.useContext(userContext);
  const [previousCount, setPreviousCount] = React.useState();

  const [filter, setFilter] = React.useState([
    {
      query: 'CUSTNMBR=',
      filter: userID,
    },
    {
      query: '&StateCode=',
      filter: '0',
    },
  ]);
  //eslint-disable-next-line
  // const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(0);
  // const [countRows, setCountRows] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const {
    isLoading,
    isError,
    data: { rows, count },
  } = useQuery(
    {
      queryKey: [userID, company, filter, page, rowsPerPage],
      queryFn: () => getRowsTableOrders(instance, accounts, page, rowsPerPage, filter, company),
      initialData: { rows: [], count: 0 },
      select: (data) => {
        const newCount = data?.data?.count;
        if (newCount !== undefined && newCount !== previousCount) {
          setPreviousCount(newCount);
          setPage(0);
        }

      //   const stateCodeFilter = filter.find((item) => item.query === '&StateCode=');
      //   const stateCodeValue = stateCodeFilter ? stateCodeFilter.filter : null;
      //   const filteredRows = data?.data?.rows?.filter((row) => {
      //     if (stateCodeValue === '0') {
      //       return row.FinishOrder === false;
      //     } else {
      //       return  row.FinishOrder === true;
      //     }
      //   });
    
      // const filteredCountRow = filteredRows?.length;
      //   return {
      //     rows: reduceDigits(filteredRows),
      //     count: newCount !== undefined ? filteredCountRow : previousCount,
      //   };
        return {
          rows: data?.data?.rows,
          count: newCount !== undefined ? newCount : previousCount,
        };
      }, //

      onSuccess: (data) => {
        if (data === undefined) {
          setPage(0);
        }
      },
      onError: (err) => {
        <div>
          <h3>Error al realizar la acción</h3>
        </div>;
      },

      onSettle: (data) => {
        if (data.data === undefined) {
          //setRows([]);
        }
      },
    },
    {
      cacheTime: 0,
    }
  );

  if (isError) {
    return (
      <div>
        <h3>Algo ha fallado..</h3>
      </div>
    );
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handlerResetFilter = () => {
    setFilter([
      {
        query: 'CUSTNMBR=',
        filter: userID,
      },
    ]);
  };

  const handleFilter = (value) => {
    value.forEach((item1) => {
      const index = filter.findIndex((item) => item.query === item1.query);

      if (index > -1) {
        if (item1.filter === '') {
          const newFilter = [...filter];
          newFilter.splice(index, 1);
          return setFilter(newFilter);
        }
        if (filter[index].filter === item1.filter) return;

        //If value is different, replace it
        const newFilter = [...filter];
        newFilter[index] = item1;
        return setFilter(newFilter);
      }

      if (item1.filter === '') {
        return;
      }
      setFilter((prev) => [...prev, item1]);
    });
  };

  return (
    <>
      <Grid
        container
        columnGap={5}
        spacing={0.5}
        paddingTop={1}
        paddingBottom={2}
        justifyContent='end'
      ></Grid>
      <Grid
        sx={{ margin: 0, paddingRight: 3 }}
        xs={2.5}
      >
        <OrdersForm
          props={{ userID, filter }}
          function={handleFilter}
          handlerResetFilter={handlerResetFilter}
        />
      </Grid>
      <Grid xs={9.5}>
        <OrdersCollapsibleActionTable
          // rows={data.data.rows}
          rows={rows}
          loading={isLoading}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          countRows={count}
          rowsPerPage={rowsPerPage}
          page={page}
        />
      </Grid>
    </>
  );
}
