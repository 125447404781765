import React, { useContext } from 'react';
import LinearChartDollar from './LinearChartDollar';
import DollarTable from './DollarTable';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import ChildCard from '../../../components/card/ChildCard';
import './styleCorteChildCards.css';
import { getListDollar } from '../../../services/dolarServices';
import { userContext } from '../../../context/userContext';
import Skeleton from '@mui/material/Skeleton';
import { useQuery } from '@tanstack/react-query';
export default function CorteUtilities() {
  const { instance, accounts } = useContext(userContext);

  const { isLoading, data } = useQuery({
    
      queryKey: ['ExchangeDate'],
      queryFn: () => getListDollar(instance,accounts),
      select: (data) =>  data.data.data,
    },
    {
      cacheTime: 60000, 
    }
);

  return (
    <>
      <Grid2
        container
        columns={12}
        spacing={3}
      >
        <Grid2 xs={4}>
          <ChildCard
          color={'#0D6878'}
            padding={0}
            paddingContent={0}
            paddingBottom={0}
            title={'Cotización del Dolar'}
            subheader={'Ultimos 30 días'}
          >
            {isLoading ? (
              <Skeleton
                variant='rectangular'
                width={'100%'}
                height={400}
              />
            ) : (
              <DollarTable data={data} />
            )}
          </ChildCard>
        </Grid2>

        <Grid2 xs={8}>
          <ChildCard
                    color={'#FF9636'}

                    padding={0}
                    paddingContent={0}
                    paddingBottom={0}
            title={'EVOLUCIÓN DEL DOLAR'}
            subheader={'Dolar Divisa'}
          >
            {isLoading ? (
              <Skeleton
                variant='rectangular'
                width={'100%'}
                height={400}
              />
            ) : (
              <LinearChartDollar data={data} />
            )}
          </ChildCard>
        </Grid2>
      </Grid2>
    </>
  );
}
