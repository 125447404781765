import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
// import AdbIcon from "@mui/icons-material/Adb";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { signOutClickHandler } from '../../services/logoutServices';
import { userContext } from '../../context/userContext';
import ColorToggleButton from './ToggleButtons';
import { useQuery } from '@tanstack/react-query';
import { getCustomerProfile } from '../../services/customerServices';
const settings = [{ key: 2, name: 'Cerrar sesion' }];

const ResponsiveAppBar = () => {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const { familyName, givenName, userID, username, instance, homeAccountId, accounts } = useContext(userContext);
  const { data } = useQuery(
    {
      queryKey: ['AccountGUID', userID],
      queryFn: () => getCustomerProfile(userID, instance, accounts),
    },
    {
      cacheTime: 60000,
    }
  );

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Box>
      <Tooltip title='Abrir opciones'>
        <IconButton
          onClick={handleOpenUserMenu}
          sx={{ p: 0 }}
        >
          <AccountCircleIcon
            sx={{ color: 'white', fontSize: 32 }}
            fontSize='large'
          />

          {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" /> */}
          <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: 1 }}>
            <Typography
              color='AliceBlue' //'RGB(225, 217, 209)'
              sx={{ marginBottom: -0.5 }}
              variant='h6'
            >
              {givenName} {familyName}
            </Typography>
            <Typography
              color='AliceBlue'
              sx={{ fontSize: 12 }}
            >
              {username}
            </Typography>
          </Box>
        </IconButton>
      </Tooltip>
      <Menu
        disableScrollLock={true}
        sx={{ mt: '48px',ml: '20px' }}
        id='menu-appbar'
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <MenuItem disabled>
            <Typography textAlign='center'>{data?.AccountName} </Typography>
          </MenuItem>
          <MenuItem disabled>
            <Typography textAlign='center'>{userID} </Typography>
          </MenuItem>
          <ColorToggleButton />
          {settings.map((setting) => (
            <MenuItem
              key={setting.key}
              sx={{width:'100%',justifyContent:'center'}}
              onClick={() => {
                handleCloseUserMenu();
                setting.name === 'Cerrar sesion' && signOutClickHandler(instance, homeAccountId);
              }}
            >
              <Typography textAlign='center'>{setting.name} </Typography>
            </MenuItem>
          ))}
        </Box>
      </Menu>
    </Box>
  );
};
export default ResponsiveAppBar;
