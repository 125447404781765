import { Avatar, Box, Card , CardContent, Typography } from '@mui/material';

export const CustomerProfile = ({ data }) => {
  return (
    <Card elevation={0} sx={{ width: 1,display:'flex', alignItems:'center',justifyContent:'center',flexDirection:'column' }}>
      <CardContent sx={{display:'flex',flexDirection:'column'}}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            padding: 0
            

          }}
        >
          <Avatar
            sx={{
              height: 64,
              mb: 1,
              width: 64,
            }}
          />
          <Typography
            color='textPrimary'
          >
            {data.AccountName}
          </Typography>
          <Typography
            color='textSecondary'
            variant='body2'
          >
            {`${data.City} ${data.Country}`}
          </Typography>
          <Typography
            color='textSecondary'
            variant='body2'
          >
            UTC-3
          </Typography>
          
         
        </Box>
       
      </CardContent>
      <Box sx={{display:'flex'}}>
        <Typography
            color='textSecondary'
            variant='body2'
            alignSelf={'center'}
            sx={{textAlign:'center'}}
          >
            Por cambios y solicitudes comunicarse con portalweb@cortechap.com.ar
          </Typography></Box>
      {/* <Divider sx={{ marginBottom: 2 }} />
      <CardActions>
      
      </CardActions> */}
    </Card>

  );
};
