import React, { useContext } from 'react';
// import PropTypes from "prop-types";
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableFooter from '@mui/material/TableFooter';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import FlagIcon from '@mui/icons-material/Flag';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { postOrderReport } from '../../../services/ordersServices';
import { userContext } from '../../../context/userContext';
import { calculateOrderStep, parseAndReplaceStepper } from '../../../helper';
import OrdersModal from './OrdersModal';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
import StepperHorizontal from '../../../components/stepper/Stepper';
import '../../../components/tables/styleTablePagination.css';
import '../../../components/tables/styleCollapsibleActionTable.css';
//
function Row(props) {
  const { row,rowIndex,handler } = props;
  const [reported, setReported] = React.useState(row.Reported === '1' ? true : false);
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow
        sx={{
          backgroundColor: open ? '#EBF0F5' : rowIndex % 2 !== 0 ? 'white' : '#EDEDED',

          '&.MuiTableRow-root:hover': {
            //  backgroundColor: 'rgb(63, 81, 181,0.11)',
            backgroundColor: open? '#EBF0F5' :'#EDEDED',
          },
        }}
      >
        {/* //rgba(0, 0, 0, 0.54) */}
        <TableCell align='center' sx={{borderBottom: open ? '1px solid #EBF0F5' : '1px solid rgba(224, 224, 224, 1)'}}>
          {row.OrderDetails.length > 0 ? (
            <IconButton
              aria-label='expand row'
              size='small'
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon sx={{color: parseInt(row.StateCode) === 0 ? '#FF9636' : '#3CB9BD'}} /> : <KeyboardArrowDownIcon sx={{color: parseInt(row.StateCode) === 0 ? '#FF9636' : '#3CB9BD'}}/>} 
            </IconButton>
          ) : null}
        </TableCell>
        <TableCell align='center' sx={{fontWeight:'bold',borderBottom: open ? '1px solid #EBF0F5' : '1px solid rgba(224, 224, 224, 1)'}}>{row.SOPNUMBE} </TableCell>
        <TableCell align='center' sx={{fontWeight: open? 550 : 400 ,borderBottom: open ? '1px solid #EBF0F5' : '1px solid rgba(224, 224, 224, 1)'}}>{format(utcToZonedTime(new Date(row.StartDate), 'UTC'), 'dd/MM/yyyy')}</TableCell>
        {/* <TableCell align='center'>{row.FinishOrder === false? 'Abierto' : 'Cerrado'}</TableCell> */}
        <TableCell align='center' sx={{fontWeight: open? 550 : 400 ,borderBottom: open ? '1px solid #EBF0F5' : '1px solid rgba(224, 224, 224, 1)'}}>{parseInt(row.StateCode) === 0 ? 'Abierto' : 'Cerrado'}</TableCell>
        <TableCell align='center' sx={{fontWeight: open? 550 : 400 ,borderBottom: open ? '1px solid #EBF0F5' : '1px solid rgba(224, 224, 224, 1)'}}>{row.PurchaseOrder}</TableCell>
        <TableCell align='center' sx={{fontWeight: open? 550 : 400 ,borderBottom: open ? '1px solid #EBF0F5' : '1px solid rgba(224, 224, 224, 1)'}}>{new Intl.NumberFormat('es-AR', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }).format(row.TotalQuantity)} kgs</TableCell>
        {/*        <TableCell align='center'>{row.AssignedQuantity} kgs</TableCell> */}
        <TableCell align='center' sx={{fontWeight: open? 550 : 400 ,borderBottom: open ? '1px solid #EBF0F5' : '1px solid rgba(224, 224, 224, 1)'}}>{new Intl.NumberFormat('es-AR', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }).format(row.InvoicedQuantity)} kgs</TableCell>
        <TableCell
          align='center'
          sx={{ paddingRight: 0,fontWeight: open? 550 : 400 ,borderBottom: open ? '1px solid #EBF0F5' : '1px solid rgba(224, 224, 224, 1)'}}  
        >
          {new Intl.NumberFormat('es-AR', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }).format(row.TotalQuantity-row.InvoicedQuantity-row.CancelledQuantity)} kgs
        </TableCell>
        <TableCell
          align='center'
          sx={{ paddingLeft: 0, paddingRight: 0, borderBottom: '0px solid transparent',color:'#6E726E',fontWeight:400 }}
        >
          {open && (
            <Tooltip
              title={row.Reported === '1' ? 'Pedido reportado' : 'Reportar pedido'}
              placement='bottom'
              describeChild={false}
              
            >
              <span>
                {/* <IconButton
               disabled={reported}
               onClick handler/row and disabled this button.
                
                onClick={() => {/
                  setReported('1');
                  handler(row)
                }}
                sx={{
                  paddingRight: 0,
                  paddingLeft: 0,
                  fontSize: 24,
                  color: 'red',
                  '&:active': { transform: 'scale(0.8)' },
                }} >*/}

                <OrdersModal
                  row={row}
                  handlerReport={setReported}
                  handler={handler}
                  reported={reported}
                  button={<FlagIcon />}
                />
                {/* </IconButton> */}
              </span>
            </Tooltip>
          )}
        </TableCell>
      </TableRow>
      {row.OrderDetails.length > 0 && (
        <TableRow sx={{ backgroundColor: open === true ? 'rgb(63, 81, 181,0.11) ' : 'white' }}>
          <TableCell
                      sx={{ backgroundColor: open === true ? '#EBF0F5 ' : 'white',borderBottom:'none' }}

                      style={{ paddingBottom: 0, paddingTop: 0,paddingLeft:0,paddingRight:0 }}
                      colSpan={9}
          >
            <Collapse
              in={open}
              timeout='auto'
              unmountOnExit
            >
              <Box >
   

                <Table
                  size='medium'
                  aria-label='purchases'
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        //  sx={{ width: '30%' }}
                        align='center'  sx={{color:'#1D1D1B',fontWeight:400 ,width:'30%'                 }}
                      >
                        Artículo
                      </TableCell>
                      <TableCell  align='center'  sx={{color:'#1D1D1B',fontWeight:400}}>Total</TableCell>

                      <TableCell  align='center'  sx={{color:'#1D1D1B',fontWeight:400}}>Pendiente</TableCell>
                      <TableCell  align='center'  sx={{color:'#1D1D1B',fontWeight:400}}>Disponible </TableCell>

                      <TableCell  align='center'  sx={{color:'#1D1D1B',fontWeight:400}}>Despachado</TableCell>

                      <TableCell align='center' sx={{color:'#1D1D1B',fontWeight:400,width:'35%'}}>Estado</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.OrderDetails.map((detail) => {
                      const rowProps = {
                        Reserved: row.Reserved,
                        StateCode: row.StateCode,
                        StatusReason: row.StatusReason,
                        ShippingMethod: row.ShippingMethod,
                      };
                      const detailProps = {
                        PendingQuantity: detail.PendingQuantity,
                        TotalQuantityAvailableStock: detail.TotalQuantityAvailableStock,
                        WaitingQuantity: detail.WaitingQuantity,
                        AssignedQuantity: detail.AssignedQuantity,
                        TotalQuantity: detail.TotalQuantity,
                        InvoicedQuantity: detail.InvoicedQuantity,
                        Invoiced: detail.Invoiced,
                        Reserved: row.Reserved,
                        ShippingMethod: row.ShippingMethod?.trim(),
                        StateCode: detail.StateCode,
                        StatusCode: row.StatusCode,
                        Product: detail.Product,
                        FulfilledQuantity: detail.FulfilledQuantity,
                        CancelledQuantity: detail.CancelledQuantity,
                        IsAssignedSameWithInvoice : detail.AssignedQuantity === detail.InvoicedQuantity ? true : false,
                        PendingQuantityv2:
                        (row.InvoicedQuantity === 0 || row.InvoicedQuantity == null
                          ? detail.TotalQuantity - Math.max(detail.AssignedQuantity, detail.FulfilledQuantity)                          - detail.CancelledQuantity :
                           detail.TotalQuantity - detail.InvoicedQuantity - detail.FulfilledQuantity - detail.CancelledQuantity ),
                          
                         /* detail.TotalQuantity -
                          detail.InvoicedQuantity -
                          detail.CancelledQuantity -
                          (detail.AssignedQuantity > detail.FulfilledQuantity
                            ? detail.AssignedQuantity
                            : detail.FulfilledQuantity)*/
                        AvailableQuantity:  detail.FulfilledQuantity //- detail.InvoicedQuantity
                         /* (row.InvoicedQuantity === 0 || row.InvoicedQuantity == null
                            ? Math.max(detail.AssignedQuantity, detail.FulfilledQuantity)
                            : detail.FulfilledQuantity)*/, //

                       /* AvailableQuantity:
                          ((row.InvoicedQuantity === 0 || row.InvoicedQuantity == null ) // esto va evaluar el invoiced Quantity
                            ? detail.AssignedQuantity > detail.FulfilledQuantity
                              ? detail.AssignedQuantity
                              : detail.FulfilledQuantity
                            : detail.FulfilledQuantity < detail.AssignedQuantity
                            ? detail.FulfilledQuantity
                            : detail.AssignedQuantity) -
                          detail.InvoicedQuantity -
                          (detail.PendingQuantity === 0 ? detail.CancelledQuantity : 0),*/
                        // AvailableQuantity: (detail.AssignedQuantity > detail.FulfilledQuantity ? detail.AssignedQuantity : detail.FulfilledQuantity) - detail.InvoicedQuantity - (detail.PendingQuantity === 0 ? detail.CancelledQuantity : 0),
                      };

                      return (
                        <TableRow key={detail.LNITMSEQ}>
                          <TableCell align='center' sx={{color:'#1D1D1B',fontWeight:400,paddingBottom: 0, paddingTop: 0 }}> {detail.Product.ArtDesc}</TableCell>
                          <TableCell align='center' sx={{color:'#1D1D1B',fontWeight:400,paddingBottom: 0, paddingTop: 0 }}>{new Intl.NumberFormat('es-AR', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }).format(detail.TotalQuantity)} kgs</TableCell>
                          <TableCell align='center' sx={{color:detailProps.PendingQuantityv2 === 0 ? '#1D1D1B' : '#E6372C',fontWeight:400,paddingBottom: 0, paddingTop: 0 }}>{new Intl.NumberFormat('es-AR', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }).format(                              Math.max(detailProps.PendingQuantityv2, 0)
        )} kgs</TableCell>
                          <TableCell align='center' sx={{color:'#1D1D1B',fontWeight:400,paddingBottom: 0, paddingTop: 0 }}>{new Intl.NumberFormat('es-AR', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }).format(detailProps.AvailableQuantity>0 ? detailProps.AvailableQuantity : 0)} kgs</TableCell>
                          <TableCell align='center' sx={{color:'#1D1D1B',fontWeight:400,paddingBottom: 0, paddingTop: 0 }}>{new Intl.NumberFormat('es-AR', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }).format(detailProps.InvoicedQuantity>0 ? detailProps.InvoicedQuantity : 0)} kgs
                            {parseAndReplaceStepper(detail.CancelledQuantity) > 0 && (
                              <Tooltip
                                title={'Cantidad cancelada ' + detail.CancelledQuantity}
                                placement='bottom'
                                describeChild={false}
                              >
                                <InfoIcon
                                  color='info'
                                  fontSize='small'
                                  sx={{ marginLeft: 1 }}
                                />
                              </Tooltip>
                            )}
                          </TableCell>

                          <TableCell align='center' sx={{color:'#1D1D1B',fontWeight:400,paddingBottom: 0, paddingTop: 0 ,paddingRight:0}}>
                            <StepperHorizontal
                              detailProps={detailProps}
                              rowProps={rowProps}
                            />
                          </TableCell>
                        </TableRow>
                        
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}

export default function OrdersCollapsibleActionTable({
  rows,
  loading,
  handleChangePage,
  handleChangeRowsPerPage,
  countRows,
  rowsPerPage,
  page,
}) {
  const { userID, instance, accounts, company } = useContext(userContext);
  const [currentRowIdx, setCurrentRowIdx] = React.useState(-1);

  const handleFlagReport = (row) => {
    const mapeo = row.OrderDetails.map((detail) => {
      const StepperStep = calculateOrderStep({
        PendingQuantity: parseAndReplaceStepper(detail.PendingQuantity),
        TotalQuantityAvailableStock: parseAndReplaceStepper(detail.TotalQuantityAvailableStock),
        WaitingQuantity: parseAndReplaceStepper(detail.WaitingQuantity),
        AssignedQuantity: parseAndReplaceStepper(detail.AssignedQuantity),
        TotalQuantity: parseAndReplaceStepper(detail.TotalQuantity),
        Invoiced: detail.Invoiced,
        Reserved: detail.Reserved,
        ShippingMethod: detail.ShippingMethod?.trim(),
        StateCode: detail.StateCode,
        StatusCode: detail.StatusCode,
        Product: detail.Product,
        FulfilledQuantity: parseAndReplaceStepper(detail.FulfilledQuantity),
      });
      return { ...detail, StepperStep: StepperStep };
    });

    const updatedRow = { ...row, OrderDetails: mapeo, UserEmail: accounts[0].username };
    console.log(updatedRow);

    postOrderReport(instance, accounts, userID, company, updatedRow); // Pasa los argumentos requeridos a través de mutate()
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label='collapsible table'>
        <TableHead>
          <TableRow sx={{ backgroundColor: '#3CB9BD' }}>
            <TableCell 
            sx={{ color:'#D9E3EA',fontWeight:400,paddingBottom:1 }}
            align='center'
            >
              Detalle
            </TableCell>

            <TableCell
              sx={{ color:'#D9E3EA',fontWeight:400,paddingBottom:1 }}
              align='center'
            >
              Pedido N°
            </TableCell>
            <TableCell
              sx={{ color:'#D9E3EA',fontWeight:400 ,paddingBottom:1}}
              align='center'
            >
              Fecha
            </TableCell>
            <TableCell
              sx={{ color:'#D9E3EA',fontWeight:400,paddingBottom:1 }}
              align='center'
            >
              Estado
            </TableCell>

            <TableCell
              sx={{ color:'#D9E3EA',fontWeight:400,paddingBottom:1 }}
              align='center'
            >
              Notas
            </TableCell>

            <TableCell
              sx={{ color:'#D9E3EA',fontWeight:400,paddingBottom:1 }}
              align='center'
            >
              Total
            </TableCell>

            <TableCell
              sx={{ color:'#D9E3EA',fontWeight:400 ,paddingBottom:1}}
              align='center'
            >
              Despachado
            </TableCell>
            <TableCell
              sx={{ color:'#D9E3EA',fontWeight:400,paddingBottom:1 }}
              align='center'
            >
              Pendiente
            </TableCell>
            <TableCell
              sx={{ color:'#D9E3EA',fontWeight:400,paddingBottom:1 }}
              align='center'
            ></TableCell>
          </TableRow>
          {loading && (
            <TableRow align='center'>
              <TableCell
                align='center'
                colSpan={9}
              >
              
                <CircularProgress />{' '}
              </TableCell>
            </TableRow>
          )}
        </TableHead>

        <TableBody>
          {loading && rows && (
            <TableRow align='center'>
              <TableCell
                align='center'
                colSpan={9}
              >
                {' '}
                <CircularProgress />{' '}
              </TableCell>
            </TableRow>
          )}
          {rows?.length > 0 && !loading ? (
            rows.map((row,index) => (
              <Row
                handler={handleFlagReport}
                key={row.OrderGUID}
                row={row}
                rowIndex={index}
                currentRowIdx={currentRowIdx}
                setCurrentRowIdx={setCurrentRowIdx}
              />
            ))
          ) : (
            <TableRow align='center'>
              <TableCell
                align='center'
                colSpan={9}
              >
                {' '}
                No hay datos para mostrar{' '}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      <TableFooter colSpan={5}>
        <TableRow colSpan={5}>
          <TableCell
            sx={{ justifyContent: 'center' }}
            colSpan={5}
            align='right'
          >
            <TablePagination
              component='div'
              sx={{ pading: '0px', margin: '0px', display: 'flex', alignItems: 'baseline' }}
              count={countRows}
              rowsPerPageOptions={[5, 10, 25]}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              variant='outlined'
              size='large'
            />
          </TableCell>
        </TableRow>
      </TableFooter>
    </TableContainer>
  );
}
