import React from 'react'
import { loginRequest } from "../../../authConfig";
import  {useMsal } from "@azure/msal-react";
import Bobinas2 from '../../../assets/Bobinas2.jpg'
import  { Navigate  } from 'react-router-dom'

const UnauthenticationTemplateBody = (props) => {
    const { instance } = useMsal();
    

    return (
        <>
  <div className="App" >
                    <header className="App-header"  style={{ backgroundImage: `url(${Bobinas2})` }}>
                        <Navigate  to={instance.loginRedirect(loginRequest)}  />
                        <p>
                            CorteChap FrontEnd Dev MaterialUI.
                        </p>
                        {/* <button onClick={() => instance.loginRedirect(loginRequest)}>
                            Login
                        </button> */}
                     
                    </header>
                </div>
        </>
    )
}

export default UnauthenticationTemplateBody