import axios from 'axios';

function getToken(instance, accounts) {
  return instance
    .acquireTokenSilent({
      scopes: [
        'https://cortechapb2c.onmicrosoft.com/corte-chap-api/orders.read',
        'https://cortechapb2c.onmicrosoft.com/corte-chap-api/invoices.read',
      ],
      account: accounts[0],
    })
    .then((response) => {
      return response.accessToken;
    });
}

export async function postResumenDeCuenta(instance, accounts, userID, company) {
  if (company === 'CORTE') {
    company = null } else {
    company = 'UNI';
    }
  console.log(company);
  const accessToken = await getToken(instance, accounts);
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  console.log(company)
  let url = `${process.env.REACT_APP_API_URL}/customers/${userID}/reports/balance`;

  if (company) {
    url += `?company=${company}`;
  }
  return axios
    .get(url, config)

    .then((response) => {
      console.log('soy el response', response);
      return response.data;
    });
}
//
export async function postResumenDeCuentaHistorico(instance, accounts, userID, company) {
  if (company === 'CORTE') {
    company = null } else {
    company = 'UNI';
    }
  console.log(company);
  const accessToken = await getToken(instance, accounts);
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  console.log(company)
  let url = `${process.env.REACT_APP_API_URL}/customers/${userID}/reports/historicalBalance`;

  if (company) {
    url += `?company=${company}`;
  }
  return axios
    .get(url, config)

    .then((response) => {
      console.log('soy el response', response);
      return response.data;
    });
}

