import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { format,parseISO } from 'date-fns';
import Typography from '@mui/material/Typography';
import { calculateOrderStep } from '../../helper/index';

export default function StepperHorizontal({ detailProps, rowProps }) {
  const [step, setStep] = useState();

  const getShipmentMethod = () => {
    if (!detailProps.Shipment || detailProps.Shipment.length === 0) {
      return null;
    }
    return detailProps.Shipment[0].ShipmentMethod;
  };

  const steps = [
    'Pedido',
    'En proceso',
    getShipmentMethod() === "100000001"
      ? 'Listo para retiro'
      : detailProps.ShippingMethod === 'FLETE INCLUIDO'
        ? 'Listo para carga'
        : 'Listo para retiro',
    detailProps.TotalQuantity === detailProps.CancelledQuantity
      ? 'Cancelado'
      : 'Finalizado',
  ];

  useEffect(() => setStep(calculateOrderStep(detailProps)), [detailProps]);

  // Verificar si existe Shipment y tiene valores
  const hasShipment = detailProps.Shipment && detailProps.Shipment.length > 0;

  // Crear la cadena con todos los elementos de Shipment
  const shipmentItems = hasShipment ? detailProps.Shipment.map(item => `${format(parseISO(item.ShipmentDate ), 'dd/MM/yy')} - ${item.Quantity} kgs`).join('<br>') : '';

  return (
    <Box sx={{ width: '100%', paddingTop: 2, paddingBottom: 1 }}>
      <Stepper
        activeStep={step}
        alternativeLabel
      >
        {steps.map((label, index) => (
          <Step key={label}>
            {step === index && label === 'En proceso' ? (
              <StepLabel
                StepIconComponent={HourglassBottomIcon}
                style={{
                  color: '#FF9636', fontSize: '30px',
                }}
              >
                {label}
              </StepLabel>
            ) : step === index && (label === 'Cancelado' || label === 'Finalizado') ? (
              <StepLabel
                StepIconComponent={LocalShippingIcon}
                style={detailProps.TotalQuantity === detailProps.CancelledQuantity ? { color: 'red', fontSize: '30px' } : { color: '#3CB9BD', fontSize: '30px' }}
              >
                {label}
              </StepLabel>
            ) : step === index && (label === 'Listo para retiro' || label === 'Listo para carga') ? (
              <StepLabel
                StepIconComponent={LocalShippingIcon}
                style={{ color: '#FF9636', fontSize: '30px' }}
              >
                {label}
                {hasShipment && (
                  <Tooltip
                  title={
                    <Typography  style={{ whiteSpace: 'pre-line', fontSize: '0.9rem' }}>
                      {shipmentItems}
                    </Typography>
                  }                    placement='right'
                    describeChild={false}
                    
                  >
                    <InfoIcon
                      color='info'
                      fontSize='small'
                      sx={{ marginLeft: 0.5 }}
                    />
                  </Tooltip>
                )}
              </StepLabel>
            ) : (
              <StepLabel>
                {label}
              </StepLabel>
            )}
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
