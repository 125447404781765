import axios from 'axios';

function getToken(instance, accounts) {
  return instance
    .acquireTokenSilent({
      scopes: [
        'https://cortechapb2c.onmicrosoft.com/corte-chap-api/orders.read',
        'https://cortechapb2c.onmicrosoft.com/corte-chap-api/invoices.read',
      ],
      account: accounts[0],
    })
    .then((response) => {
      return response.accessToken;
    });
}

export async function getCustomerProfile(IdUser, instance, accounts) {
  const accessToken = await getToken(instance, accounts);

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios

    .get(`${process.env.REACT_APP_API_URL}/customers/${IdUser}`, config)
    .then((res) => res.data.data)
    .catch((error) => {
      console.log(error);
    });
}
