import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import FlagIcon from '@mui/icons-material/Flag';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function OrdersModal({ button, handler, row,reported,handlerReport}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [observation, setObservation] = React.useState('');

  const handlerFunction = () => {
    const rowWithObservation = { ...row, Observacion: observation };
    handlerReport('1')
    handler(rowWithObservation);
    handleClose();
  };

  const handleChangeObservation = (event) => {
    setObservation(event.target.value);
  };


  return (
    <>
      <IconButton
        aria-label='delete'
        size='small'
        onClick={handleOpen}
        disabled={reported}
        sx={{
            paddingRight: 0,
            paddingLeft: 0,
            fontSize: 24,
            color: 'red',
            '&:active': { transform: 'scale(0.8)' },
          }} 
      >
        {button}
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Detalle del reporte
          </Typography>
        
          <TextField
          label="Observación"
            id="observacion"
            multiline
            rows={4}
            variant='outlined'
            fullWidth
            value={observation}
            onChange={handleChangeObservation}
            sx={{ mt: 2 }}
          />
          <Box style={{display:'flex',justifyContent: 'space-between'}}>
          
          
          <Button onClick={handleClose} sx={{ mt: 2,mr:5 ,
              
                backgroundColor: '#004D40',
                color: 'white',
                '&:hover': { backgroundColor: '#00a884', color: 'white' },
              }}>
            Cancelar
          </Button>
          <Button onClick={handlerFunction} sx={{ mt: 2,
                backgroundColor: '#004D40',
                color: 'white',
                '&:hover': { backgroundColor: '#00a884', color: 'white' },
              }}>
            Guardar y reportar
          </Button>
          </Box>
        </Box>
      </Modal>
</>
  );
}
