export default function roundTotalQuantity(data) {
    if(data === undefined) return data;
    data.forEach((item) => {
        for (const prop in item) {
            if (typeof item[prop] === "number") {
                if (prop === "TotalQuantity" || prop === "PendingQuantity" || prop === "AssignedQuantity") {
                    item[prop] = item[prop].toLocaleString("es-AR", {minimumFractionDigits: 0, maximumFractionDigits: 0});
                } else if (item[prop] % 1 !== 0) {
                    item[prop] = item[prop].toLocaleString("es-AR", {minimumFractionDigits: 2, maximumFractionDigits: 2});
                } else {
                    item[prop] = item[prop].toLocaleString("es-AR", {minimumFractionDigits: 0, maximumFractionDigits: 0});
                }
            } else if (Array.isArray(item[prop])) {
                item[prop].forEach((detail) => {
                    for (const detailProp in detail) {
                        if (typeof detail[detailProp] === "number") {
                            if (detailProp === "TotalQuantity" || detailProp === "PendingQuantity" || detailProp === "AssignedQuantity") {
                                detail[detailProp] = detail[detailProp].toLocaleString("es-AR", {minimumFractionDigits: 0, maximumFractionDigits: 0});
                            } else if (detail[detailProp] % 1 !== 0) {
                                detail[detailProp] = detail[detailProp].toLocaleString("es-AR", {minimumFractionDigits: 2, maximumFractionDigits: 2});
                            } else {
                                detail[detailProp] = detail[detailProp].toLocaleString("es-AR", {minimumFractionDigits: 0, maximumFractionDigits: 0});
                            }
                        }
                    }
                });
            }
        }
    });
    return data;
}