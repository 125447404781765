import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import ReceiptIcon from '@mui/icons-material/Receipt';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HelpPopup from './HelpPopup';
import AccountBoxSharpIcon from '@mui/icons-material/AccountBoxSharp';
import DashboardSharpIcon from '@mui/icons-material/DashboardSharp';
import ReceiptSharpIcon from '@mui/icons-material/ReceiptSharp';
import FolderSharpIcon from '@mui/icons-material/FolderSharp';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import projectLogo from '../../assets/logoCorteChap.svg';
import projectLogoPetroleo from '../../assets/logoPetroleo.svg';
import projectLogoBlanco from '../../assets/logoBlanco.svg';
import ResponsiveAppBar from './ResponsiveAppBar';
import './styleMiniDrawer.css';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Grid from '@mui/material/Grid';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import {DollarBox} from './DollarBox';
import {userContext} from '../../context/userContext';
import ReactGA from '../../config/gaConfig'; // Ruta ajustada según la estructura de carpetass
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  overflowY:'hidden' //overflow
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',

  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export default function MiniDrawer(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const { pathname } = useLocation();
  const {adminUser} = React.useContext(userContext)
  const [helpOpen, setHelpOpen] = React.useState(false);
  const location = useLocation();

  React.useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location]);


  const handleHelpClick = () => {

    setHelpOpen(true); // Abre el popup de ayuda al hacer clic en el menú de Ayuda
  };

  const handleCloseHelp = () => {
    setHelpOpen(false); // Cierra el popup de ayuda
  };

  const ListaDeMenuConsultas = [
    {
      name: 'Inicio',
      url: '/',
      icon: <DashboardSharpIcon sx={{ fontSize: 16 ,color:'#6E726E'}} />,
    },
    {
      name: 'Pedidos',
      url: '/orders',
      icon: <ReceiptSharpIcon sx={{ fontSize: 16,color:'#6E726E' }} />,
    },
    {
      name: 'Comprobantes',
      url: '/invoices',
      icon: <ReceiptIcon sx={{ fontSize: 16 ,color:'#6E726E'}} />,
    },
    {
      name: 'Estado de Cuenta',
      url: '/estadoCuenta',
      icon: <FolderSharpIcon sx={{ fontSize: 16,color:'#6E726E' }} />,
    },
    {
      name: 'Perfil',
      url: '/customers',
      icon: <AccountBoxSharpIcon sx={{ fontSize: 16,color:'#6E726E' }} />,
    }
  ];

  const ListaDeMenuAcercaDe = [
    {
      name: 'Canales de Atención',
      url: '/blankpage',
      icon: <DashboardSharpIcon sx={{ fontSize: 16,color:'#6E726E' }} />,
    },
    {
      name: 'Legajo Impositivo',
      url: '/blankpage',
      icon: <ReceiptSharpIcon sx={{ fontSize: 16,color:'#6E726E' }} />,
    },
    {
      name: 'Reclamos de Calidad',
      url: '/blankpage',
      icon: <AccountBoxSharpIcon sx={{ fontSize: 16,color:'#6E726E' }} />,
    },
    {
      name: 'Utilidades',
      url: '/utilities',
      icon: <MonetizationOnIcon sx={{ fontSize: 16 ,color:'#6E726E'}} />,
    },
    {
     name: 'Ayuda',
   //  url: '/',
     icon: <HelpIcon sx={{ fontSize: 16 ,color:'#6E726E'}} />,
    },


   

  ];
  if(adminUser){
    ListaDeMenuAcercaDe.push({
      name: 'Administración',
      url: '/admin',
      icon: <AccountBoxSharpIcon sx={{ fontSize: 16,color:'#6E726E' }} />,
    })
  }
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline
      />

      <AppBar
        position='fixed'
        open={open}
      
      >
        <Toolbar
                sx={{display:'flex',justifyContent: 'space-between',
                 backgroundColor: theme.palette.background.header
                }}
                >
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            edge='start'
            sx={{
        
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box    sx={{flexGrow: 3,display:'flex',justifyContent:'space-between'}}>

          <Typography
           sx={{fontSize:24,fontWeight:250}}
            noWrap
            component='div'
          >
{pathname === '/' ? 'Inicio' : pathname === '/orders' ? 'Pedidos' : pathname === '/invoices' ? 'Comprobantes' : pathname === '/customers' ? 'Perfil' : pathname === '/error' ? 'Documentos' : pathname === '/about' ? 'Acerca de' : pathname === '/about/contacts' ? 'Canales de Atención' : pathname === '/about/tax' ? 'Legajo Impositivo' : pathname === '/about/quality' ? 'Reclamos de Calidad' : pathname === '/admin' ? 'Panel de Administración' : pathname === '/estadoCuenta' ? 'Estado de Cuenta': pathname === '/utilities' ? 'Utilidades' : 'Inicio'}
          </Typography></Box>
          <Box sx={{display:'flex',alignContent:'end',justifiyContent:'end'}}>
                  <ResponsiveAppBar />
                  </Box>
        </Toolbar>

      </AppBar>
      <Drawer
        variant='permanent'
        open={open}
      >
        <DrawerHeader sx={{backgroundColor: theme.palette.background.header}}>

          <Box width={'100%'} style={{ textAlign: 'center'}}>
        <img
              height='100%'
              src={projectLogoBlanco}
              alt='Logo'
            />
</Box>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon sx={{color:'white'}} /> : <ChevronLeftIcon  sx={{color:'white'}} />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
        

          {open ? (
            <Typography
              p={2}
              variant='subtitle2'
              sx={{color:'#3CB9BD'}}
            >
              CONSULTAS
            </Typography>
          ) : null}
          {ListaDeMenuConsultas.map((menu, index) => (
            <ListItem
              key={index}
              disablePadding
              sx={{ display: 'block' }}
            >
              
              <ListItemButton
                sx={{
                  minHeight: 32,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2,
                  paddingTop:0.5
                }}
                to={menu.url}
                as={Link}
                
                style={{ color: '#000' }}
              >
                <ListItemIcon
                  sx={{
                    marginRight: 0,
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center'
                  }}
                >
                  {menu.icon}
                </ListItemIcon>
                <ListItemText
                                
                                primary={
                                  <Typography  sx={{ fontSize: '13px',fontWeight:500}}>
                                    {menu.name}
                                  </Typography>
                                }
                  // primary={menu.name}
                  sx={{ opacity: open ? 1 : 0, color: '#17171799'  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        {/* <Divider /> */}
        {open ? (
          <Typography
          p={2}
          variant='subtitle2'
          sx={{color:'#3CB9BD'}}
          >
            ACERCA DE CORTE-CHAP
          </Typography>
        ) : (
          <Divider />
        )}
        <List>
          {ListaDeMenuAcercaDe.map((menu, index) => (
            <ListItem
              key={index}
              disablePadding
              sx={{ display: 'block' ,fontWeight: 500 }}
              disabled={(index === 2 || index === 1 || index === 0) ? true : false}
            >
              {index === 2 || index === 1 || index === 0 ? (
                <Tooltip
                  title='Próximamente'
                  placement='bottom-end'
                  describeChild={false}
                >
                  <ListItemButton
                           onClick={menu.name === 'Ayuda' ? () => handleHelpClick() : undefined}
                    sx={{
                      minHeight: 32,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2,
                      paddingTop:0.5

                    }}
                  
                    style={{ color: '#000' }}
                  >
                    <ListItemIcon
                   

                      sx={{
                        marginRight: 0,
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      {menu.icon}
                    </ListItemIcon>
                    <ListItemText
                             
                              primary={
                                <Typography  sx={{ fontSize: '13px',fontWeight:500}}>
                                {menu.name}
                                </Typography>
                              }
                     //primary={menu.name}
                      sx={{ opacity: open ? 1 : 0, color: '#17171799' }}
                    />
                  </ListItemButton>
                </Tooltip>
              ) : (
                <ListItemButton
                onClick={menu.name === 'Ayuda' ? () => handleHelpClick() : undefined}

                  sx={{
                    minHeight: 32,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2,
                    paddingTop:0.5,
                    width: menu.name === 'Ayuda' ? '100%' : 'auto' // Ancho completo para 'Ayuda'
                  }}
                  
                  to={menu.url}
                  
                  component={menu.name === 'Ayuda' ? 'button' : Link}

                //  as={Link}
                  style={{ color: '#000' }}
                >
                  <ListItemIcon
                    sx={{
                      marginRight: 0,
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    {menu.icon}
                  </ListItemIcon>
                  <ListItemText
                           
                           primary={
                            <Typography  sx={{ fontSize: '13px',fontWeight:500}}>
                            {menu.name}
                            </Typography>
                          }
                 //primary={menu.name}
                    sx={{ opacity: open ? 1 : 0, color: '#17171799' }}
                  />
                </ListItemButton>
              )}
            </ListItem>
          ))}

        </List>
        
          <Grid sx={{flexDirection:'column',paddingBottom:2.5,width:'100%',height:'100%',display:'flex',alignContent:'end',justifyContent:'flex-end',alignItems:'center'}}>
          <DollarBox open={open}/>


          <IconButton
                    onClick={()=>{window.open('https://api.whatsapp.com/send?phone=+5493516781234&text=Hola%20quisiera%20un%20presupuesto%20de', '_blank')}}
variant='text'
          sx={{borderRadius: '100%',height: 60,color:'white'}}

          >
          <WhatsAppIcon 
          
          sx={{ fontSize: (open? 36 : 30),color:'green' }}/></IconButton>
          </Grid>
          </Drawer>
          <HelpPopup open={helpOpen} onClose={handleCloseHelp}/>

      <Box
        component='main'
        sx={{ flexGrow: 1, p: 3 }}
      >
        <DrawerHeader />
        {props.children}

      </Box>

    </Box>
    
  );
}
