import React, { useContext } from 'react';
import { ResponsiveContainer, BarChart, Label, Bar, LabelList, XAxis, Tooltip, YAxis } from 'recharts';
import { userContext } from '../../../context/userContext';
import { Skeleton,Box } from '@mui/material';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
function getToken(instance, accounts) {
  return instance
    .acquireTokenSilent({
      scopes: [
        'https://cortechapb2c.onmicrosoft.com/corte-chap-api/orders.read',
        'https://cortechapb2c.onmicrosoft.com/corte-chap-api/invoices.read',
      ],
      account: accounts[0],
    })
    .then((response) => {
      return response.accessToken;
    });
}

const renderCustomizedLabel = (props) => {
  const { content, ...rest } = props;

  return (
    <Label
      {...rest}
      fontSize='10'
      fill='#000000'
      //fontWeight='Bold'
      fontWeight= '500'
      familyFont= 'encode sans'
    />
  );
};

const renderCustomizedLabelWhite = (props) => {
  const { content, ...rest } = props;

  return (
    <Label
      {...rest}
      fontSize='10'
      fill='#FFFFFF'
      fontWeight= '500'
      familyFont= 'encode sans'
      //fontWeight='Bold'
    />
  );
};

async function llamarAPI(userID, instance, accounts, company) {
  const accessToken = await getToken(instance, accounts);

  const config = {
    headers: {
      'Cache-Control': 'max-age=31536000',
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios
    .get(`${process.env.REACT_APP_API_URL}/orders/evolution?CUSTNMBR=${userID}&Company=${company}`, config)
    .then((res) => {
      return res.data.data;
    });
}

const CustomTooltipContent = ({ label, payload, active }) => {
  const tooltipStyle = {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    border: '1px solid #ccc',
  //  border:'0.5px solid rgb(204, 204, 204)',
    padding: '10px',
    fontSize: '14px',
    margin:0
    
  };

  const labelStyle = {
    marginBottom: '2px',
    fontWeight: 450,
    lineHeight:'1.5',
    familyFont: 'encode sans',
  };

  const listStyle = {
    padding: '0',
    listStyle: 'none',
    margin: '0',
    display:'block',
 
    marginInlineStart: '0px',
    marginInlineEnd: '0px',
  };

  const itemStyle = {
    display: 'flex',
    flexDirection: 'column',
  };

  const cantidadAsignadaStyle = {
    display:'block',
    paddingTop:'2px',
    paddingBottom:'2px',
   color:'#3CB9BD',
    fontSize:'1rem',
    fontWeight: 400,
    lineHeight:'1.5',
    familyFont: 'encode sans',

   
  };

  const cantidadPendienteStyle = {
    display:'block',
    paddingTop:'2px',
    paddingBottom:'2px',
    color:'rgb(255, 198, 88)',
    fontSize:'1rem',
    fontWeight: 400,
    lineHeight:'1.5',
    familyFont: 'encode sans',
  }

  const cantidadTotalStyle = {
    display:'block',
    paddingTop:'2px',
    paddingBottom:'2px',
    color:'black',
    fontSize:'1rem',
    fontWeight: 400,
    lineHeight:'1.5',
    familyFont: 'encode sans',
  }

  const cantidadCanceladaStyle = {
    display:'block',
    paddingTop:'2px',
    paddingBottom:'2px',
    color:'violet',
    fontWeight: 400,
    lineHeight:'1.5',
    familyFont: 'encode sans',
  }

  if (active && payload && payload.length) {
    const data = payload[0].payload;

    return (
      <div style={tooltipStyle}>
        <p style={labelStyle}>Estadísticas {label}</p>
        <ul style={listStyle}>
          <li style={itemStyle}>
           
            <div style={{ display: 'flex', marginBottom: 2, }}>
              <span style={ cantidadAsignadaStyle }>TN Despachadas:</span>
              <span style={cantidadAsignadaStyle}>{ new Intl.NumberFormat('es-AR').format(data.InvoicedQuantity)}</span>
            </div>
            {/* <div style={{ display: 'flex', marginBottom: 2, }}>
              <span style={ cantidadPendienteStyle }>TN Asignadas:</span>
              <span style={cantidadPendienteStyle}>{ new Intl.NumberFormat('es-AR').format(data.AssignedQuantity-data.InvoicedQuantity)}</span>
            </div> */}
            <div style={{ display: 'flex', marginBottom: 2 }}>
              {' '}
              <span style={cantidadPendienteStyle}>TN Pendientes:</span>
              <span style={cantidadPendienteStyle}>{new Intl.NumberFormat('es-AR').format(data.PendingQuantity)}</span>

              {/* <span style={cantidadPendienteStyle}>{new Intl.NumberFormat('es-AR').format(data.TotalQuantity - (data.InvoicedQuantity + data.CancelledQuantity))}</span> */}
            </div>
             <div style={{ display: 'flex', marginBottom: 2, }}>
              <span style={ cantidadTotalStyle }>TN Totales:</span>
              <span style={cantidadTotalStyle}>{ new Intl.NumberFormat('es-AR').format(data.TotalQuantity)}</span>
            </div>
            {/* <div style={{ display: 'flex', marginBottom: 2 }}>
              {' '}
              <span style={cantidadCanceladaStyle}>TN Canceladas:</span>
              <span style={cantidadCanceladaStyle}>{data.CancelledQuantity == null ? 0 : new Intl.NumberFormat('es-AR').format(data.CancelledQuantity)}</span>
            </div> */}
          </li>
        </ul>
      </div>
    );
  }

  return null;
};

const EvolutionStackedChartLine = () => {
  const { userID, instance, accounts, company } = useContext(userContext);

  const { isLoading, isError, data } = useQuery(
    {
      queryKey: ['TotalQuantity', 'PendingQuantity', 'OpenOrders', company, userID],
      queryFn: () => llamarAPI(userID, instance, accounts, company)

    },
    {
      cacheTime: 60000,
    }
  );

  if (isError) {
    return (
      <div>
        {' '}
        <h3>No hay datos disponibles en este momento, por favor refresque la página para reintentar.</h3>
      </div>
    );
  }

  return (
    <>
    {isLoading ? (
      <Box sx={{height:390,paddingLeft:2,paddingRight:2,paddingTop:2}}>
      <Skeleton
        sx={{ backgroundColor: '#EDEDED' }} //rgb(63, 81, 181,0.11)
        variant='rectangular'
        height={'100%'}
        width={'100%'}
      /></Box>
    )
       : (
        
    <ResponsiveContainer
    width= {'100%'}
    height={390}
  >
        <BarChart
          height={400}
          data={data}
          margin={{ top: 25,right:8, left: 8, bottom: 0 }}
        >
          <XAxis
            style={{ fontSize: 12 }}
            dataKey='MonthYear'
            tick={{ fontSize: 12 }}
            stroke='#000000'

       
          />
          
          <Tooltip

            cursor={false}
           content={<CustomTooltipContent />}
          />

          <Bar
            isAnimationActive={false}
            barSize={100}
            dataKey='InvoicedQuantity'
            stackId='1'

            stroke='#3CB9BD'
          //  fill='#82ca9d'
           fill='#3CB9BD'
            id='barea'
            name='InvoicedQuantity'
            cursor={'pointer'}
          >
            <LabelList
              dataKey='InvoicedQuantity'
              position='inside'
              content={renderCustomizedLabelWhite}
              formatter={(label) => {
                return label > 0 ?  new Intl.NumberFormat('es-AR',{
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }).format(label) : null;
              }}
            />
          </Bar>
          <Bar
            isAnimationActive={false}
            barSize={100}
            cursor={'pointer'}
            dataKey='PendingQuantity'
            stackId='1'
            stroke='#FF9636'
           // fill='#ffc658'
           fill='#FF9636'
            id='carea'
            name='PendingQuantity'
          >
            <LabelList
              content={renderCustomizedLabelWhite}
              dataKey='PendingQuantity'
              position='inside'
              formatter={(label) => {
                return label > 0 ?  new Intl.NumberFormat('es-AR',
                {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }).format(label) : null;
              }}
            />
          </Bar>

          <Bar
            isAnimationActive={false}
            stackId={1}
          >
            <LabelList
              dy={-3}
              content={renderCustomizedLabel}
              dataKey='TotalQuantity'
              position='top'
              formatter={(label) => {
                return label > 0 ?  new Intl.NumberFormat('es-AR',
                {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                
                }).format(label) : null}}
            />
          </Bar>
        </BarChart>
        </ResponsiveContainer>

      )}</>
  );
};

export default EvolutionStackedChartLine;
