import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close'; // Importar el icono Close

const HelpPopup = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={'lg'}>
      
      <DialogTitle>
        Ayuda
        {/* <CloseIcon aa
        fontSize='large'
        sx={{ position: 'absolute', top: 0, right: 0, color: 'grey', cursor: 'pointer' }}
            onClick={onClose}
          />  */}
        <Button onClick={onClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
          <CloseIcon />
        </Button>
      </DialogTitle>
      <DialogContent sx={{ width: '100%', height: '80vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <object
          data="https://cortechap.blob.core.windows.net/customerportal/Corte-Chap%20-%20Gu%C3%ADa%20de%20uso%20portal%20web.pdf?sp=r&st=2023-12-06T17:10:24Z&se=2029-12-07T01:10:24Z&spr=https&sv=2022-11-02&sr=b&sig=JeyWS1elxdRMocGgMkY1wChfD%2FfZQ2Kv2kcfDhdwUXE%3D"
          type="application/pdf"
          width="100%"
          height="100%"
        >
          El navegador no puede mostrar este archivo PDF.
        </object>
      </DialogContent>
      {/* <DialogActions>
        <Button onClick={onClose}>Cerrar</Button>
      </DialogActions> */}
    </Dialog>
  );
};

export default HelpPopup;