
export function  signOutClickHandler(instance, homeAccountId) {

    instance.logoutRedirect( {
    account: instance.getAccountByHomeId(homeAccountId),
    postLogoutRedirectUri: 'https://cortechap.com.ar',
    
  });
}




