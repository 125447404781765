import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { userContext } from '../../../context/userContext';
import ColorToggleButton from '../../../components/drawer/ToggleButtons';
import Box from '@mui/material/Box';
import ChildCard from '../../../components/card/ChildCard';
import AdminEditableGrid from './AdminEditableGrid';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { getUsers } from '../../../services/userServices';
import { patchUsers } from '../../../services/userServices';
import LinearProgress from '@mui/material/LinearProgress';
import { useNavigate } from 'react-router-dom';
import ConfirmationDialog from './ConfirmationDialog'; // Import the dialog component

function Admin() {
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const [setMutateLoading] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [numeroCuenta, setNumeroCuenta] = useState('');
  const { handleUpdateUserID, userID, company, handleUpdateCompany, adminUser, instance, accounts } =
    React.useContext(userContext);
  const { isFetching, isLoading, isError, data } = useQuery(
    {
      queryKey: [
        'userID',
        'extension_bdac175cd6a5496290f63c140b584cc5_AccountID',
        'extension_bdac175cd6a5496290f63c140b584cc5_CUIT',
        'givenName',
        'surname',
      ],
      queryFn: () => getUsers(instance, accounts),
      retry: 1,
    },
    {
      cacheTime: 60000, // cache for 1 minute
    }
  );

  const updateUserData = useMutation(
    (editBuffer) => {
      const promises = Object.entries(editBuffer).map(([id, data]) => {
        console.log(id, data);
        data.id = id;
        return patchUsers(instance, accounts, id, data);
      });

      Promise.all(promises)
        .then(() => {
          setTimeout(() => setConfirmationDialogOpen(true), 2000);
          setTimeout(hideMessage, 6000);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['surname'] });
        console.log('estoy en el refetch');
        setMutateLoading(false);
        setAlertMessage('Se ha modificado el registro');
        setConfirmationDialogOpen(true);
        setTimeout(hideMessage, 3000);
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  const hideMessage = () => {
    setConfirmationDialogOpen(false);
  };

  const handleNumeroCuentaChange = (event) => {
    setNumeroCuenta(event.target.value);
  };

  const handleConsultaClick = () => {
    handleUpdateUserID(numeroCuenta);
    setTimeout(() => {
      navigate('/');
    }, 250);
  };

  const handleCompanyChange = (newCompany) => {
    handleUpdateCompany(newCompany);
  };

  if (!adminUser) return <div>Acceso Denegado</div>;

  return (
    <Box>
      <Typography
        sx={{ fontSize: 36, fontWeight: '300', color: '#6E726E' }}
        align='center'
        gutterBottom
      >
        Panel de Administrador
      </Typography>
      <Grid container justifyContent='center' gap={1} marginBottom={2}>
        <Grid item xs={3} sm={3} justifyContent='center'>
          <TextField
            label='Número de Cuenta'
            fullWidth
            value={numeroCuenta}
            onChange={handleNumeroCuentaChange}
            margin='normal'
            variant='outlined'
          />
          <Button
            variant='contained'
            fullWidth
            onClick={handleConsultaClick}
            sx={{
              backgroundColor: '#3CB9BD',
              color: 'white',
              '&:hover': { backgroundColor: '#3CB9BD', color: 'white' },
            }}
          >
            Cambiar
          </Button>
          {userID && (
            <Typography
              variant='body1'
              align='center'
              gutterBottom
              fontStyle='italic'
            >
              Cuenta Actual: {userID}
            </Typography>
          )}
        </Grid>

        <Grid
          container
          xs={3}
          sm={3}
          direction={'column'}
          alignItems='center'
          justifyContent='center'
        >
          <ColorToggleButton onChange={handleCompanyChange} />
          <Typography
            variant='body1'
            align='center'
            gutterBottom
            style={{ marginTop: '1rem' }}
          >
            Compañía Actual: {company}
          </Typography>
        </Grid>
      </Grid>
      <ChildCard
        color={'#3CB9BD'}
        title='Edición de Usuarios'
        subheader='Haga doble clic a continuación en cada celda para modificar los valores y luego accione el botón.'
        padding={0}
        paddingContent={0}
        paddingBottom={0}
      >
        {!isLoading && !isError && (
          <AdminEditableGrid
            updateUserData={updateUserData}
            rows={data.data.data}
            disable={confirmationDialogOpen}
          />
        )}
        {isLoading && isFetching && <LinearProgress />}
      </ChildCard>
      <ConfirmationDialog
        open={confirmationDialogOpen}
        onClose={hideMessage}
        title="Operación Exitosa"
        message="Se ha modificado el registro correctamente."
      />
    </Box>
  );
}

export default Admin;
