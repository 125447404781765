import axios from 'axios';

function getToken(instance, accounts) {
  return instance
    .acquireTokenSilent({
      scopes: [
        'https://cortechapb2c.onmicrosoft.com/corte-chap-api/orders.read',
        'https://cortechapb2c.onmicrosoft.com/corte-chap-api/invoices.read',
      ],
      account: accounts[0],
    })
    .then((response) => {
      return response.accessToken;
    });
}

export async function getUsers(instance, accounts, userID, company) {
  const accessToken = await getToken(instance, accounts);

  const config = {
    headers: {
      'Cache-Control': 'max-age=31536000',
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios
    .get(`${process.env.REACT_APP_API_URL}/users/all`, config)
    // .get(`https://test-backend-cortechap.azurewebsites.net/v1/users/all`, config)
    .then((response) => {
      console.log('dentro del fetch ', response)
      return response;
    });



}

export async function patchUsers(instance, accounts, id, data) {
  const accessToken = await getToken(instance, accounts);

  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'max-age=31536000',
      Authorization: `Bearer ${accessToken}`,
    },
  };

  data.id = id;

  return axios
    .post(`${process.env.REACT_APP_API_URL}/users/${id}`, JSON.stringify(data),
   // .post(`http://localhost:8080/v1/users/${id}`, JSON.stringify(data),

      {
        headers: config.headers
      })
      .then((response) => {
        return response;
      }
      )
      .catch((error) => {
        console.log(error);
      }
      );
}