import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import React, { useEffect, useState } from 'react';

export default function LinearChartDollar({ data }) {
  const [dollar, setDollar] = useState();
  const [viewDisplay, setViewDisplay] = useState({
    Divisa: false,
    Billete: false,
  });

  function handlerViewer(e) {
    setViewDisplay({ ...viewDisplay, [e.payload.name]: !viewDisplay[e.payload.name] });
  }

  useEffect(() => {
    const sortedDataDivisa = data
      .filter((item) => item.CurrencyName.trim() === 'DOLAR DIVISA')
      .sort((a, b) => new Date(b.ExchangeDate) - new Date(a.ExchangeDate))
      .slice(0, 30);
    const sortedDataBillete = data
      .filter((item) => item.CurrencyName.trim() === 'DOLAR BILLETE')
      .sort((a, b) => new Date(b.ExchangeDate) - new Date(a.ExchangeDate))
      .slice(0, 30);
    const fullArray = sortedDataDivisa.map((item, index) => {
      return {
        ExchangeDate: item.ExchangeDate,
        ExchangeRateDivisa: item.ExchangeRate,
        ExchangeRateBillete: sortedDataBillete[index].ExchangeRate,
      };
    });
    setDollar(fullArray.reverse());
  }, [data]);

  return (
    dollar && (
      <ResponsiveContainer
        width={'100%'}
        height={400}
      >
        <LineChart
          data={dollar}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis
            dataKey='ExchangeDate'
            padding={{ left: 30, right: 30 }}
          />

         <YAxis domain={[345,355]} /> 

          <Tooltip />
          <Legend onClick={(e) => handlerViewer(e)} />

          <Line
            isAnimationActive={false}
            position='bottom'
            hide={viewDisplay.Divisa}
            name='Divisa'
            type='monotone'
            dataKey='ExchangeRateDivisa'
            stroke='#3CB9BD'
          />
        </LineChart>
      </ResponsiveContainer>
    )
  );
}
