import { useState,useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@mui/material';



export const CustomerDetails = ({data,context}) => {
 const {given_name,family_name,extension_CUIT} = context[0].idTokenClaims

  const [values, setValues] = useState({
  });

  useEffect(() => {
    setValues({
      firstName: given_name,
      lastName: family_name,
      cuit: extension_CUIT,
      emailAzureAD: context[0].idTokenClaims.emails[0],
      email: data?.EmailAddress1,
      phone: data?.Phone1.replace('-','').trim(),
      state: data?.State.replace('ó','o'),
      country: data?.Country,
      segment: data?.Segment,
      subsegment: data?.Subsegment,
      AccountID: data?.AccountID
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])



  
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  return (
    <form
      autoComplete="off"
      noValidate
    >
      <Card
        elevation={0}

      sx={{mb:1}}>
        <CardHeader
  elevation={0}
  subheader="Información del usuario"
          title="Perfil"
          sx={{backgroundColor:'#3CB9BD'}}
          subheaderTypographyProps={{color:'white',fontWeight:100,fontSize:16}}
          titleTypographyProps={{color:'white',fontWeight:100,fontSize:18}}
        />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Nombre"
                name="firstName"
                onChange={handleChange}
                required
                
                value={values.firstName}
                variant="outlined"
                InputProps={{
                  readOnly: true,
                  sx:{
                  fontWeight:200
                }}}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Apellido"
                name="lastName"
                onChange={handleChange}
                required
                value={values.lastName}
                variant="outlined"
               InputProps={{
                  readOnly: true,
                  sx:{
                  fontWeight:200
                }}}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Correo electrónico"
                name="emailAzureAD"
                onChange={handleChange}
                required
                value={values.emailAzureAD}
                variant="outlined"
               InputProps={{
                  readOnly: true,
                  sx:{
                  fontWeight:200
                }}}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="CUIT"
                name="cuit"
                onChange={handleChange}
                type="text"
                value={values.cuit}
                variant="outlined"
               InputProps={{
                  readOnly: true,
                  sx:{
                  fontWeight:200
                }}}
              />
            </Grid>
          </Grid>
          
        </CardContent>

        <Divider  />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 0,
           
          }}
        >

        </Box>
      </Card>
      <Card         elevation={0}
>
        <CardHeader
          title="Información comercial"
          // subheader="Para modificaciones comunicarse con portalweb@cortechap.com.ar"
          // subheaderTypographyProps={{color:'white',fontWeight:100,fontSize:16}}

          sx={{backgroundColor:'#0D6878'}}
          titleTypographyProps={{color:'white',fontWeight:100,fontSize:18}}          />
        <CardContent  sx={{pb:16}}>
          <Grid
            container
            spacing={2}
            
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                // helperText="Por favor especifique el nombre"
                label="Correo electrónico comercial"
                name="correoComercial"
                onChange={handleChange}
                value={values.email}
                required
                variant="outlined"
               InputProps={{
                  readOnly: true,
                  sx:{
                  fontWeight:200
                }}}
              />
            </Grid>
       
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="País"
                name="country"
                onChange={handleChange}
                required
                value={values.country}
                variant="outlined"
               InputProps={{
                  readOnly: true,
                  sx:{
                  fontWeight:200
                }}}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Correo electrónico de administración"
                name="correoAdministracion"
                onChange={handleChange}
                required
                value={values.correoAdministracion}
                variant="outlined"
               InputProps={{
                  readOnly: true,
                  sx:{
                  fontWeight:200
                }}}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
                 <TextField
                fullWidth
                label="Provincia"
                name="state"
                onChange={handleChange}
                required
                value={values.state}
                variant="outlined"
               InputProps={{
                  readOnly: true,
                  sx:{
                  fontWeight:200
                }}}
              />
          
            </Grid> 
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Correo electrónico de calidad"
                name="correoCalidad"
                onChange={handleChange}
                required
                value={values.correoCalidad}
                variant="outlined"
               InputProps={{
                  readOnly: true,
                  sx:{
                  fontWeight:200
                }}}
              />
            </Grid>
           
               <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Numero de teléfono"
                name="phone"
                onChange={handleChange}
                type="number"
                value={values.phone}
                variant="outlined"
               InputProps={{
                  readOnly: true,
                  sx:{
                  fontWeight:200
                }}}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Domicilio legal"
                name="domicilioLegal"
                onChange={handleChange}
                value={values.domicilioLegal}
                required
                variant="outlined"
               InputProps={{
                  readOnly: true,
                  sx:{
                  fontWeight:200
                }}}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
            <TextField
                fullWidth
                label="Número de cuenta"
                name="AccountID"
                onChange={handleChange}
                required
                value={values.AccountID}
                variant="outlined"
               InputProps={{
                  readOnly: true,
                  sx:{
                  fontWeight:200
                }}}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Segmento"
                name="segment"
                onChange={handleChange}
                value={values.segment}
                required
                variant="outlined"
               InputProps={{
                  readOnly: true,
                  sx:{
                  fontWeight:200
                }}}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Subsegmento"
                name="Subsegmento"
                onChange={handleChange}
                value={values.subsegment}
                required
                variant="outlined"
               InputProps={{
                  readOnly: true,
                  sx:{
                  fontWeight:200
                }}}
              />
            </Grid>
 
          </Grid>
        </CardContent>
        
       
      </Card>
    </form>
  );
};
